import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { plainToInstance } from "class-transformer";
import { ProfileDto } from "../../dto";
import { PROFILE_ME, PROFILE_UPDATE } from "../endpoint.constants";

interface IProfileUpdateRequest {
  firstName: string;
  lastName: string;
  email: string;
}

const TAG = "PROFILE";
const ID_ME = "ME";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [TAG],
  endpoints: (builder) => ({
    me: builder.query<ProfileDto, any>({
      query: (query) => ({
        url: PROFILE_ME,
        method: "GET",
        query,
      }),

      transformResponse: (response: any) =>
        plainToInstance(ProfileDto, response),

      providesTags: [{ type: TAG, id: ID_ME }],
    }),

    updateProfile: builder.mutation<IProfileUpdateRequest, any>({
      query: (body) => ({
        url: PROFILE_UPDATE,
        method: "PUT",
        body,
      }),

      invalidatesTags: [{ type: TAG, id: ID_ME }],
    }),
  }),
});

export const { useMeQuery, useUpdateProfileMutation } = profileApi;
