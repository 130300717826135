import { useMeQuery } from "../store/api";

export const useUser = () => {
  const { data, isLoading } = useMeQuery({});

  return {
    data,
    isLoading,
  };
};
