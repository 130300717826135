export const API_ENDPOINT = "https://api.example.com";

export const PROFILE_ME = "/api/v1/profile";
export const PROFILE_UPDATE = "/api/v1/profile";

export const NOTIFICATION_FIND_ALL = "/api/v1/notification";
export const NOTIFICATION_UPDATE = "/api/v1/notification";
export const NOTIFICATION_CREATE = "/api/v1/notification";
export const NOTIFICATION_FIND_ONE = "/api/v1/notification/:id";

export const PARTY_FIND_ALL = "/api/v1/party";
export const PARTY_UPDATE = "/api/v1/party";
export const PARTY_CREATE = "/api/v1/party";
export const PARTY_FIND_ONE = "/api/v1/party/:id";

export const _TEMPLATE_FIND_ALL = "/api/v1/_template";
export const _TEMPLATE_UPDATE = "/api/v1/_template";
export const _TEMPLATE_CREATE = "/api/v1/_template";
export const _TEMPLATE_FIND_ONE = "/api/v1/_template/:id";
