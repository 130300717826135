import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "auth-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: "relative",
      minHeight: "100vh",
      background: "url(/assets/images/auth-bg.jpg) no-repeat center",
      backgroundSize: "cover",
    },
    container: {
      position: "relative",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      padding: theme.spacing(0, 2),
    },
  }),
  options
);

export default useStyles;
