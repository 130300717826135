import { useUser } from "../hooks/useUser";
import { LoadingLayout } from "../../components/layouts/loading-layout";

export const withAuthorized = (ComposedComponent: any) => (props: any) => {
  const { data, isLoading } = useUser();

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (!data) {
    return "Forbidden";
  }

  return <ComposedComponent {...props} />;
};
