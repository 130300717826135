import { LabeledList } from "../../../components/labeled-list";
import { Grid } from "@mui/material";
import { formatNumber } from "../../../core";
import { StatsCard } from "../../../components/stats-card";
import { AdaptiveImage } from "../../../components/adaptive-image";
import { WorkingHours } from "../../../components/working-hours";
import { formatToDate } from "../../../core/utils/date.utils";

export const Detail = ({ payload, statisticsPayload }) => {
  return (
    <>
      <Grid container spacing={2} mb={5}>
        <Grid item lg={3} md={6} xs={12}>
          <StatsCard
            title="Total commitments"
            value={formatNumber(statisticsPayload?.totalCommitments) || "-"}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <StatsCard
            title="Male users"
            value={formatNumber(statisticsPayload?.maleUsersCount) || "-"}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <StatsCard
            title="Female users"
            value={formatNumber(statisticsPayload?.femaleUsersCount) || "-"}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <StatsCard
            title="Av. arrival time"
            value={statisticsPayload?.avgArrivalTime || "-"}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} mb={5}>
        <Grid item lg={7} md={12}>
          <LabeledList
            mb={3}
            items={[
              {
                label: "ID",
                value: payload?.id.uuid || "-",
              },
              {
                label: "Name",
                value: payload?.name || "-",
              },
              {
                label: "Category",
                value:
                  payload?.categories.map((item) => item).join(", ") || "-",
              },
              {
                label: "Address",
                value: payload?.address.address || "-",
              },
              {
                label: "Phone",
                value: payload?.phone || "-",
              },
              {
                label: "Working hours",
                value:
                  payload?.workingHours && payload?.workingHours.length ? (
                    <WorkingHours workingHours={payload?.workingHours} />
                  ) : (
                    "-"
                  ),
              },
              {
                label: "Is Featured",
                value: payload?.isFeatured ? "Yes" : "No" || "-",
              },
              {
                label: "Created",
                value: formatToDate(payload?.createdAt) || "-",
              },
              {
                label: "Updated",
                value: formatToDate(payload?.updatedAt) || "-",
              },
            ]}
          />
        </Grid>
        <Grid item lg={5} md={12}>
          <AdaptiveImage src={payload?.imageUrl} alt="" />
        </Grid>
      </Grid>
    </>
  );
};
