import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { ROUTE_PARTIES } from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { partiesData } from "../../core/_mockdata";
import { StatusBadge } from "../../components/badges";

export const PartyDetailContainer = () => {
  const { id } = useParams();
  const [openConfirm] = useDialog(ConfirmDialog);
  const payload = find(partiesData, { id: +id }) as any;

  const handleDeactivate = () => {
    openConfirm({
      title: "Deactivate party",
      message: "Are you sure you want to deactivate this party?",
      onConfirm: () => {
        console.log("Deactivate");
      },
    });
  };

  return (
    <>
      <DetailHeader
        title={payload?.name}
        header={
          <Breadcrumbs
            items={[
              { label: "Parties", url: ROUTE_PARTIES },
              {
                label: payload?.name,
              },
            ]}
          />
        }
        meta={<StatusBadge label="Upcoming" variant="success" />}
      >
        <Button color="secondary" variant="outlined" onClick={handleDeactivate}>
          Deactivate
        </Button>
      </DetailHeader>

      <Detail payload={payload} />
    </>
  );
};
