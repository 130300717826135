import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { AUTH_REQUEST_CODE, AUTH_VERIFY_CODE } from "../../http/api.routes";

interface IRequestCodeRequest {
  phone: string;
}

interface IVerifyCodeRequest {
  phoneNumber: string;
  code: string;
}

interface IVerifyCodeResponse {
  accessToken: string;
}

export const signInApi = createApi({
  reducerPath: "signInApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    requestCode: builder.mutation<IRequestCodeRequest, any>({
      query: (params) => ({
        url: AUTH_REQUEST_CODE,
        method: "POST",
        body: params,
      }),
    }),
    verifyCode: builder.mutation<IVerifyCodeResponse, IVerifyCodeRequest>({
      query: (params) => ({
        url: AUTH_VERIFY_CODE,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useRequestCodeMutation, useVerifyCodeMutation } = signInApi;
