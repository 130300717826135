import { FC, useEffect, useRef, useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import IconButton from "@mui/material/IconButton";
import { IconArrowLeft } from "../../../components/icons";
import { FieldPhone } from "../../../components/fields";
import { useNavigate } from "react-router-dom";
import { ROUTE_DASHBOARD } from "../../../core";
import { useRequestCode } from "../../../core/hooks/useRequestCode";
import { useVerifyCode } from "../../../core/hooks/useVerifyCode";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { AUTH_TOKEN } from "../../../core/constants/app.constants";

export const SignInForm: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [counter, setCounter] = useState(60);
  const [step, setStep] = useState<"phone" | "code">("phone");
  const phoneRef = useRef(null);
  const codeRef = useRef(null);
  const classes = useStyles();

  const {
    getFieldProps: getRequestCodeFieldProps,
    setFieldValue: setRequestCodeFieldValue,
    handleSubmit: handleRequestCodeSubmit,
    isLoading: isRequestCodeLoading,
    values: requestCodeValues,
  } = useRequestCode({
    onSuccess: () => {
      showMessage(
        "SMS code was successfully sent on your phone number",
        "success"
      );
      setStep("code");
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  useEffect(() => {
    if (phoneRef.current) {
      const input = phoneRef.current.querySelector("input");
      if (input && input.focus) {
        input.focus();
      }
    }
  }, [phoneRef]);

  useEffect(() => {
    if (step === "code") {
      if (codeRef.current) {
        codeRef.current.focus();
      }
    }
  }, [step, codeRef]);

  const {
    getFieldProps: getVerifyCodeFieldProps,
    setFieldValue: setVerifyCodeFieldValue,
    handleSubmit: handleVerifyCodeSubmit,
    values: verifyCodeValues,
    isLoading: isVerifyCodeLoading,
  } = useVerifyCode({
    onSuccess: (token) => {
      showMessage(
        "SMS code was successfully verified. You are logged in",
        "success"
      );
      localStorage.setItem(AUTH_TOKEN, token);
      navigate(ROUTE_DASHBOARD);
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  const handleBack = () => {
    setStep("phone");
  };

  useEffect(() => {
    if (step === "code") {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      }
    } else {
      setCounter(60);
    }
  }, [step, counter]);

  return (
    <>
      {step === "code" && (
        <IconButton className={classes.backButton} onClick={handleBack}>
          <IconArrowLeft />
        </IconButton>
      )}

      <Box className={classes.root}>
        <Box className={classes.header}>
          <img
            className={classes.logo}
            src={"/assets/images/logo.svg"}
            alt="WYA Logo"
          />
          <Typography align="center" className={classes.title}>
            Admin Panel
          </Typography>
          {step === "phone" && (
            <Typography color="text.secondary" variant="body1">
              Sign in
            </Typography>
          )}
          {step === "code" && requestCodeValues.phoneNumber && (
            <Typography
              color="text.secondary"
              variant="body1"
              textAlign="center"
            >
              {`We sent SMS code on your phone number *${requestCodeValues.phoneNumber.substr(
                requestCodeValues.phoneNumber.length - 4
              )}. Please enter SMS code
              below.`}
            </Typography>
          )}
        </Box>

        {step === "phone" && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleRequestCodeSubmit();
            }}
          >
            <Stack spacing={3} mb={2}>
              <FieldPhone
                label="Phone number"
                {...getRequestCodeFieldProps("phoneNumber")}
                onChange={(value) => {
                  setVerifyCodeFieldValue("phoneNumber", value);
                  setRequestCodeFieldValue("phoneNumber", value);
                }}
                inputRef={phoneRef}
              />
            </Stack>
            <Button
              size="large"
              variant="contained"
              fullWidth
              type="submit"
              sx={{ mb: 2 }}
              disabled={
                isRequestCodeLoading || requestCodeValues.phoneNumber === ""
              }
            >
              Send code
            </Button>
          </form>
        )}

        {step === "code" && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleVerifyCodeSubmit();
            }}
          >
            <Stack spacing={3} mb={2}>
              <TextField
                label="SMS code"
                inputProps={{ maxLength: 6 }}
                inputRef={codeRef}
                {...getVerifyCodeFieldProps("code")}
              />
            </Stack>
            <Button
              size="large"
              variant="contained"
              fullWidth
              type="submit"
              sx={{ mb: 1 }}
              disabled={
                isVerifyCodeLoading || verifyCodeValues.code.length !== 6
              }
            >
              Login
            </Button>
            <Button
              type="button"
              size="large"
              color="secondary"
              variant="text"
              fullWidth
              onClick={() => {
                handleRequestCodeSubmit();
                setCounter(60);
              }}
              disabled={counter > 0}
            >
              Send again {counter > 0 ? `in ${counter}` : ""}
            </Button>
          </form>
        )}
      </Box>
    </>
  );
};
