import { createApi } from "@reduxjs/toolkit/query/react";
import {
  baseQuery,
  FindOneProps,
  ListResponse,
  listResponse,
} from "../rtk.utils";
import { plainToInstance } from "class-transformer";
import {
  NOTIFICATION_CREATE,
  NOTIFICATION_FIND_ALL,
  NOTIFICATION_FIND_ONE,
  NOTIFICATION_UPDATE,
} from "../endpoint.constants";
import { url } from "../../utils";
import { NotificationDto } from "../../dto";
import { FormikValues } from "formik";

const TAG = "NOTIFICATION";
const ID = "ID";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [TAG],
  endpoints: (builder) => ({
    /**
     * Find all
     */
    findAllNotification: builder.query<
      ListResponse<NotificationDto>,
      Record<string, any>
    >({
      query: (query) => ({
        url: NOTIFICATION_FIND_ALL,
        method: "GET",
        query,
      }),
      transformResponse: (response) =>
        listResponse<NotificationDto>(NotificationDto, response),
      providesTags: [{ type: TAG, id: ID }],
    }),

    /**
     * Find one
     */
    findOneNotification: builder.query<
      NotificationDto,
      FindOneProps<NotificationDto["id"]>
    >({
      query: ({ id, params = {} }) => ({
        url: url(NOTIFICATION_FIND_ONE, { id }),
        params,
      }),
      transformResponse: (response) =>
        plainToInstance(NotificationDto, response),
      keepUnusedDataFor: 0,
    }),

    /**
     * Create one
     */
    createOneNotification: builder.mutation<NotificationDto, FormikValues>({
      query: (body) => ({
        url: NOTIFICATION_CREATE,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),

    /**
     * Update one
     */
    updateOneNotification: builder.mutation<
      NotificationDto,
      { id: NotificationDto["id"]; body: FormikValues }
    >({
      query: ({ id, body }) => ({
        url: url(NOTIFICATION_UPDATE, { id }),
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),
  }),
});

export const {
  useFindAllNotificationQuery,
  useFindOneNotificationQuery,
  useCreateOneNotificationMutation,
  useUpdateOneNotificationMutation,
} = notificationApi;
