import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import {
  GET_PLACES,
  GET_PLACE_BY_ID,
  MARK_PLACE_AS_FEATURED,
  MARK_PLACE_AS_UNFEATURED,
  DELETE_PLACE,
  GET_PLACE_TOKEN_BY_ID,
  UPDATE_PLACE_TOKEN_BY_ID,
  GET_PLACE_STATISTICS_BY_ID,
  GET_PLACE_CATEGORIES,
  CREATE_PLACE,
  EDIT_PLACE,
  GET_ADDRESSES,
} from "../../http/api.routes";
import { url } from "../../utils";

interface IGetPlacesResponse {
  items: IGetPlacesItem[];
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

interface IGetPlacesItem {
  id: string;
  name: string;
  imageUrl: string;
  isFeatured: boolean;
  address: IGetPlacesAddress;
}

interface IGetPlacesAddress {
  address: string;
  city: string;
  zipCode: string;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
}

interface IGetPlaceByIdResponse {
  id: string;
  name: string;
  category: string;
  phone: string;
  address: IGetPlaceByIdAddress;
  imageUrl: string;
  isFeatured: boolean;
  createdAt: string;
  updatedAt: string;
  categories: string[];
  workingHours: IGetPlaceByIdWorkingHours[];
}

interface IGetPlaceByIdWorkingHours {
  dayIndex: number;
  startTime: string;
  endTime: string;
  isOvernight: boolean;
}

interface IGetPlaceByIdAddress {
  id: string;
  locationId: string;
  address: string;
  addressAdditional: string;
  city: string;
  zipCode: string;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
}

interface IPlaceActionsRequest {
  placeId: string;
}

interface IGetPlaceTokenByIdResponse {
  id: any;
  placeId: string;
  description: string;
  preview: string;
}

interface IGetPlaceStatisticsByIdResponse {
  totalCommitments: number;
  maleUsersCount: number;
  femaleUsersCount: number;
  avgArrivalTime: string;
}

interface IEditPlaceRequest {
  id: string;
  name: string;
  phone: string;
  rawCategories: string[];
  workingHours: string[];
}

interface ICreatePlaceRequest {
  id: string;
  name: string;
  phone: string;
  address: {};
  rawCategories: string[];
  workingHours: string[];
}

interface IGetAddressesItem {}

interface IGetAddressesResponse {
  items: IGetAddressesItem[];
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

export const placesApi = createApi({
  reducerPath: "placesApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getPlaces: builder.query<IGetPlacesResponse, any>({
      query: (params) => ({
        url: GET_PLACES,
        method: "GET",
        params,
      }),
    }),
    getPlaceById: builder.query<IGetPlaceByIdResponse, any>({
      query: (params) => ({
        url: url(GET_PLACE_BY_ID, { id: params.id }),
        method: "GET",
        params,
      }),
    }),
    getPlaceCategories: builder.query<any, any>({
      query: () => ({
        url: url(GET_PLACE_CATEGORIES),
        method: "GET",
      }),
    }),
    getPlaceTokenById: builder.query<IGetPlaceTokenByIdResponse, any>({
      query: (params) => ({
        url: url(GET_PLACE_TOKEN_BY_ID, { id: params.id }),
        method: "GET",
      }),
    }),
    updatePlaceTokenById: builder.mutation<IGetPlaceTokenByIdResponse, any>({
      query: (params) => ({
        url: url(UPDATE_PLACE_TOKEN_BY_ID, { id: params.placeId }),
        method: "PUT",
        body: params,
      }),
    }),
    getPlaceStatisticsById: builder.query<IGetPlaceStatisticsByIdResponse, any>(
      {
        query: (params) => ({
          url: url(GET_PLACE_STATISTICS_BY_ID, { id: params.id }),
          method: "GET",
          params,
        }),
      }
    ),
    markPlaceAsFeatured: builder.mutation<IPlaceActionsRequest, any>({
      query: (params) => ({
        url: url(MARK_PLACE_AS_FEATURED, { placeId: params.placeId }),
        method: "PUT",
      }),
    }),
    markPlaceAsUnfeatured: builder.mutation<IPlaceActionsRequest, any>({
      query: (params) => ({
        url: url(MARK_PLACE_AS_UNFEATURED, { placeId: params.placeId }),
        method: "PUT",
      }),
    }),
    deletePlace: builder.mutation<IPlaceActionsRequest, any>({
      query: (params) => ({
        url: url(DELETE_PLACE, { placeId: params.placeId }),
        method: "DELETE",
      }),
    }),
    editPlace: builder.mutation<IEditPlaceRequest, any>({
      query: (params) => ({
        url: url(EDIT_PLACE, { placeId: params.id }),
        method: "PUT",
        body: params,
      }),
    }),
    createPlace: builder.mutation<ICreatePlaceRequest, any>({
      query: (params) => ({
        url: CREATE_PLACE,
        method: "POST",
        body: params,
      }),
    }),
    getAddresses: builder.query<IGetAddressesResponse, any>({
      query: (params) => ({
        url: GET_ADDRESSES,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetPlacesQuery,
  useGetPlaceByIdQuery,
  useGetPlaceTokenByIdQuery,
  useUpdatePlaceTokenByIdMutation,
  useGetPlaceStatisticsByIdQuery,
  useMarkPlaceAsFeaturedMutation,
  useMarkPlaceAsUnfeaturedMutation,
  useDeletePlaceMutation,
  useGetPlaceCategoriesQuery,
  useEditPlaceMutation,
  useCreatePlaceMutation,
  useGetAddressesQuery,
} = placesApi;
