import React from "react";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_NOTIFICATIONS } from "../../core";
import { times } from "lodash";
import { useFormik } from "formik";
import { FieldAutocompleteSelect } from "../../components/fields";
import { PageHeader } from "../../components/page-header";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconExpandMore } from "../../components/icons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export const NotificationFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: { location: [], publicationDate: new Date() },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <PageHeader title={isEdit ? "Edit Notification" : "Add new Notification"}>
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_NOTIFICATIONS)}
        >
          Cancel
        </Button>
      </PageHeader>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <TextField
            label="Text"
            {...getFieldProps("text")}
            size="small"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={2}>
        <Grid item lg={6} md={6} xs={12}>
          <FieldAutocompleteSelect
            label="Location"
            size="small"
            options={times(10, (i) => ({
              label: `Location ${i + 1}`,
              value: `location-${i + 1}`,
            }))}
            value={values.location}
            onChange={(value) => {
              setFieldValue("location", value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={2}>
        <Grid item lg={3} md={3} xs={12}>
          <DatePicker
            label="Date"
            components={{
              OpenPickerIcon: IconExpandMore,
            }}
            value={values.publicationDate}
            onChange={(newValue) => setFieldValue("publicationDate", newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={12}>
          <TimePicker
            label="Start time"
            components={{
              OpenPickerIcon: IconExpandMore,
            }}
            value={values.publicationDate}
            onChange={(newValue) => setFieldValue("publicationDate", newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mx: -3, mb: 3 }} />
    </>
  );
};
