import { ReactComponent as SvgPlus } from "./plus.svg";
import { ReactComponent as SvgMinus } from "./minus.svg";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconPlus = (props: SvgIconProps) => (
  <SvgIcon component={SvgPlus} viewBox="0 0 16 16" {...props} />
);

export const IconMinus = (props: SvgIconProps) => (
  <SvgIcon component={SvgMinus} viewBox="0 0 16 16" {...props} />
);
