import React, { ComponentType } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {
  DataTableColumn,
  DataTableRowExpandable,
  DataTableRowSelection,
} from "./TableView";
import { Checkbox } from "../fields";
import get from "lodash/get";
import IconButton from "@mui/material/IconButton";
import { IconMinus, IconPlus } from "./icons";

interface DataTableRowProps {
  item: Record<string, any>;
  columnsCount: number;
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onClick?: (item: Record<string, any>) => void;
  selectedRows: any[];
  setSelectedRows: (id: any) => void;

  expandable?: DataTableRowExpandable;
  renderProps: any;
  keyExtractor: (item: Record<string, any>) => string;
  expandedIsOpen?: number;
  setExpandedIsOpen: (id: any) => void;
}

export const TableViewRow: ComponentType<DataTableRowProps> = ({
  columnsCount,
  rowSelection,
  item,
  columns,
  onClick,
  selectedRows,
  setSelectedRows,
  expandable,
  renderProps = {},
  keyExtractor,
  expandedIsOpen,
  setExpandedIsOpen,
}) => {
  const id = keyExtractor(item);
  const isSelectable = !!rowSelection;
  const isSelected = selectedRows.includes(id);
  const isExpandable =
    expandable && expandable.rowExpandable && expandable.rowExpandable(item);

  const handleSelect = () => {
    setSelectedRows(id);
  };

  const handleClick = () => {
    onClick && onClick(item);
  };

  const handleExpandToggle = (e) => {
    e.stopPropagation();
    const isOpen = String(expandedIsOpen) === String(id);
    setExpandedIsOpen(isOpen ? null : id);
  };

  const open = String(expandedIsOpen) === String(id);

  return (
    <>
      <TableRow
        onClick={handleClick}
        sx={{
          "&:hover": {
            backgroundColor: "#F4F6FF",
          },

          ...(open && {
            backgroundColor: "#F4F6F8!important",
            borderColor: "#F4F6F8!important",
          }),
        }}
      >
        {isSelectable && (
          <TableCell>
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={handleSelect}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = get(item, column.dataIndex);
          return (
            <TableCell
              key={keyExtractor(item) + "_" + column.key}
              {...column.cellProps}
            >
              {column.render ? column.render(value, item, renderProps) : value}
            </TableCell>
          );
        })}
        {isExpandable && (
          <TableCell
            sx={{
              maxWidth: 48,
              padding: "0!important",
              textAlign: "center",
            }}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor: "transparent",
                "& svg": { fontSize: 14 },
              }}
              onClick={handleExpandToggle}
            >
              {open ? <IconMinus /> : <IconPlus />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isExpandable && (
        <TableRow
          sx={{
            ...(open && {
              backgroundColor: "#F4F6F8!important",
              borderColor: "#ECF0F7!important",
            }),
          }}
        >
          <TableCell
            sx={{
              padding: "0!important",
              border: "none!important",
            }}
            colSpan={columnsCount}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ backgroundColor: "background.light" }}
            >
              <Box sx={{ padding: 2, paddingTop: 0 }}>
                {expandable.expandedRowRender &&
                  expandable.expandedRowRender(item)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
