import { FC, PropsWithChildren, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { Divider } from "@mui/material";
import useStyles from "./styles";
import clsx from "clsx";
import { useResponsive } from "../../core/hooks/useResponsive";

interface Props {
  header?: ReactNode;
  withTabs?: boolean;
  title: ReactNode;
  meta?: ReactNode;
  divider?: boolean;
  TitleProps?: TypographyProps;
  RootProps?: BoxProps;
}

export const PageHeader: FC<PropsWithChildren<Props>> = ({
  header,
  title,
  divider = true,
  meta,
  children,
  TitleProps,
  RootProps,
  withTabs = false,
}) => {
  const classes = useStyles();
  const { isMobile } = useResponsive();

  return (
    <>
      <Box
        className={clsx(classes.root, {
          [classes.rootWithTabs]: withTabs,
        })}
        {...RootProps}
      >
        {header && header}
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h4" {...TitleProps}>
              {title}
            </Typography>
            {meta && (
              <Stack direction="row" spacing={1} className={classes.meta}>
                {meta}
              </Stack>
            )}
          </Stack>
          {children && (
            <Stack direction="row" spacing={1} className={classes.action}>
              {children}
            </Stack>
          )}
        </Stack>
      </Box>
      {divider && <Divider sx={{ mx: -3, mb: 3 }} />}
    </>
  );
};
