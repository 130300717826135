import { LabeledList } from "../../../components/labeled-list";
import { format } from "date-fns";
import React from "react";
import { Link } from "@mui/material";
import { AttendedDialog } from "../dialogs/AttendedDialog";
import { useDialog } from "../../../core/hooks/useDialog";

export const Detail = ({ payload }) => {
  const [openAttendedDialog] = useDialog(AttendedDialog);

  const handleOpenAttended = (e) => {
    e.preventDefault();
    openAttendedDialog({ id: payload?.id });
  };

  return (
    <>
      <LabeledList
        mb={3}
        items={[
          {
            label: "ID",
            value: payload?.id,
          },
          {
            label: "Host",
            value: [payload?.host?.firstName, payload?.host?.lastName].join(
              " "
            ),
          },
          {
            label: "Name",
            value: payload?.name,
          },
          {
            label: "Date",
            value: format(new Date(payload?.startTime), "dd/MM/yyyy"),
          },
          {
            label: "Time",
            value:
              format(new Date(payload?.startTime), "hh:mm a") +
              " - " +
              format(new Date(payload?.endTime), "hh:mm a"),
          },
          {
            label: "Location",
            value: payload?.location,
          },
          {
            label: "Address",
            value: payload?.address,
          },
          {
            label: "Invited",
            value: (
              <Link href="#" onClick={handleOpenAttended} color="common.black">
                {payload?.invitedCount}
              </Link>
            ),
          },
          {
            label: "Attended",
            value: (
              <Link href="#" onClick={handleOpenAttended} color="common.black">
                {payload?.attendedCount}
              </Link>
            ),
          },
          {
            label: "Description",
            value: payload?.description,
          },
          {
            label: "Link",
            value: (
              <Link
                href={`https://wya.app/events/535335834593503909`}
                color="common.black"
              >
                www.wya.app/events/535335834593503909
              </Link>
            ),
          },
          {
            label: "Created",
            value: "05/07/2023 10:16 PM",
          },
          {
            label: "Updated",
            value: "05/07/2023 10:16 PM",
          },
        ]}
      />
    </>
  );
};
