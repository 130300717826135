import { ComponentType } from "react";
import { CheckboxProps, Checkbox as MuiCheckbox } from "@mui/material";
// import {
//   IconCheckbox,
//   IconCheckboxChecked,
//   IconCheckboxIndeterminate
// } from '../../icons';

export const Checkbox: ComponentType<CheckboxProps> = (props) => {
  return (
    <MuiCheckbox
      color="secondary"
      // icon={<IconCheckbox />}
      // checkedIcon={<IconCheckboxChecked />}
      // indeterminateIcon={<IconCheckboxIndeterminate />}
      {...props}
      sx={{ padding: 0 }}
      {...props}
    />
  );
};
