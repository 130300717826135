import React, { useState } from "react";
import { WorkingHoursLabel } from "./working-hours-label";
import Typography from "@mui/material/Typography";
import { times } from "lodash";
import { addDays } from "date-fns";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import { IconArrowDown } from "../icons";

interface WorkingHour {
  isOvernight: boolean;
  startTime: string;
  endTime: string;
  dayIndex: number;
}

interface Props {
  workingHours: WorkingHour[];
}

const DayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const WorkingHours: React.FC<Props> = ({ workingHours }) => {
  const [showHours, setShowHours] = useState(false);

  const getCurrentDayOfWeek = () => {
    const today = new Date();
    return today.getDay();
  };

  const formatTime = (time: string) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2, 4);
    const formattedHours = parseInt(hours, 10) % 12;
    const amPm = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes} ${amPm}`;
  };

  const getDayWorkingHours = (day: number) => {
    const currentWorkingHours = workingHours.find(
      (hour) => hour.dayIndex === day
    );
    if (currentWorkingHours) {
      const start = formatTime(currentWorkingHours.startTime);
      const end = formatTime(currentWorkingHours.endTime);
      return `${start} - ${end}`;
    }
    const today = getCurrentDayOfWeek();

    return today ? "Closed today" : "Closed";
  };

  const now = new Date();

  return (
    <Box>
      <Box onClick={() => setShowHours(!showHours)}>
        <Stack direction="row" spacing={2} alignItems="center">
          <WorkingHoursLabel workingHours={workingHours} />
          <IconArrowDown
            sx={{
              transform: showHours ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Stack>
        {showHours && (
          <Box mt={1}>
            {times(7, (i) => {
              const day = addDays(now, i);
              const dayOfWeek = day.getDay();

              return (
                <Stack key={dayOfWeek} mb={1} direction="row">
                  <Box width={100}>
                    <Typography variant="body2">
                      {DayOfWeek[dayOfWeek]}:
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {getDayWorkingHours(dayOfWeek)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
