import TextTruncate from "react-text-truncate";
import { formatToDate } from "../utils/date.utils";
import { formatNumber } from "../utils";

export const notificationColumns = [
  {
    title: "Text",
    dataIndex: "text",
    key: "text",
    render: (text) => <TextTruncate line={1} truncateText="…" text={text} />,
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Recipients",
    dataIndex: "recipientsCount",
    key: "recipientsCount",
    render: (text) => formatNumber(text),
  },
  {
    title: "IsSent",
    dataIndex: "IsSent",
    key: "isSent",
    cellProps: {
      width: 70,
    },
    render: (text) => (text ? "Yes" : "No"),
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => formatToDate(text),
  },
];
