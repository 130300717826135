import React, { FC, useRef } from "react";
import { TextField, Autocomplete as MuiAutocomplete } from "@mui/material";

import useStyles from "./styles";

interface Item {
  label: string;
  value: string;
}

interface Props {
  label: string;
  isLabelGrey?: boolean;
  placeholder?: string;
  value: string;
  onChange?: (value: any) => void;
  dataSource: Item[];
  disabled?: boolean;
  sx?: any;
  size?: any;
  width?: string;
  fullWidth?: boolean;
  loading?: boolean;
}

export const FieldAutocomplete: FC<Props> = ({
  value,
  label,
  isLabelGrey,
  placeholder,
  size,
  onChange,
  dataSource,
  disabled = false,
  sx,
  width = "auto",
  fullWidth,
  loading,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const classes = useStyles();
  const firstAutocompleteElementRef = useRef<any>(null);

  return (
    <div
      style={{ position: "relative", minWidth: "150px", width: width }}
      className={isLabelGrey && classes.labelGrey}
    >
      {loading && (
        <div
          style={{
            color: "#637381",
            position: "absolute",
            left: "15px",
            top: "17px",
          }}
        >
          Loading...
        </div>
      )}
      <MuiAutocomplete
        fullWidth={fullWidth}
        sx={sx}
        options={dataSource}
        value={value}
        size={size}
        getOptionLabel={(option: Item) => option.label}
        disabled={disabled}
        loading={loading}
        onChange={(event, newValue: any) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            disabled={disabled}
            {...params}
            {...{ label, placeholder }}
            helperText={null}
          />
        )}
        onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
          if (e.key === "Enter" || e.key === "NumpadEnter") {
            firstAutocompleteElementRef.current.click();
          }
        }}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        renderOption={(
          props: React.HTMLAttributes<HTMLLIElement>,
          option: Partial<any>
        ) => {
          return (
            <li
              ref={
                props["data-option-index"] === 0
                  ? firstAutocompleteElementRef
                  : undefined
              }
              {...props}
              key={option.value}
            >
              {option.label}
            </li>
          );
        }}
      />
    </div>
  );
};
