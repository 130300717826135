import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'field-radio-group'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: '1px solid rgba(145, 158, 171, 0.12)',
      backgroundColor: theme.palette.common.white,
      borderRadius: 8
    },
    button: {
      borderRadius: 8,
      color: '#637381',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '26px',
      padding: theme.spacing(1.25)
    },
    buttonActive: {
      color: theme.palette.common.black,
      backgroundColor: 'rgba(84, 214, 44, 0.16)'
    }
  }),
  options
);

export default useStyles;
