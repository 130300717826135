import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { format, getDay, setDay } from "date-fns";

interface Props {
  workingHours: {
    isOvernight: boolean;
    startTime: string;
    endTime: string;
    dayIndex?: number;
  }[];
}

export const WorkingHoursLabel: React.FC<Props> = ({ workingHours }) => {
  const [isOpenNow, setIsOpenNow] = useState(false);
  const [nextOpenTime, setNextOpenTime] = useState("");

  useEffect(() => {
    updateOpenStatus();
  }, []);

  const convertTo12HourFormat = (time: string) => {
    const hours = parseInt(time.substring(0, 2));
    const minutes = time.substring(2);
    const meridian = hours >= 12 ? "PM" : "AM";
    const convertedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${convertedHours}:${minutes} ${meridian}`;
  };

  const updateOpenStatus = () => {
    const now = new Date();
    const today = now.getDay();
    const currentTime = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    for (let i = 0; i < workingHours.length; i++) {
      const { isOvernight, startTime, endTime, dayIndex } = workingHours[i];
      if (dayIndex === today) {
        if (isOvernight) {
          if (currentTime >= startTime || currentTime < endTime) {
            setIsOpenNow(true);
            setNextOpenTime(convertTo12HourFormat(endTime));
            return;
          }
        } else {
          if (currentTime >= startTime && currentTime < endTime) {
            setIsOpenNow(true);
            setNextOpenTime("");
            return;
          }
        }
      }
      if (dayIndex > today) {
        if (isOvernight) {
          setIsOpenNow(true);
          setNextOpenTime(convertTo12HourFormat(endTime));
          return;
        } else {
          setIsOpenNow(false);
          setNextOpenTime(
            `Opens at ${convertTo12HourFormat(startTime)} ${
              dayIndex > today + 1
                ? "on " +
                  setDay(new Date(), dayIndex).toLocaleString("en-us", {
                    weekday: "long",
                  }) +
                  ","
                : ""
            }`
          );
          return;
        }
      }
    }
    setIsOpenNow(false);
    const todayWeekDay = getDay(new Date());
    let nextOpenDay;

    for (let i = 0; i < 7; i++) {
      const dayIndex =
        todayWeekDay + i > 6 ? todayWeekDay + i - 7 : todayWeekDay + i;
      nextOpenDay = workingHours.find((item) => item.dayIndex === dayIndex);
      if (nextOpenDay) break;
    }

    setNextOpenTime(
      nextOpenDay && Object.keys(nextOpenDay).length
        ? `Closed, next open day is ${setDay(
            new Date(),
            nextOpenDay.dayIndex
          ).toLocaleString("en-us", {
            weekday: "long",
          })}`
        : "Closed"
    );
  };

  return (
    <Box>
      {isOpenNow ? (
        <span>
          <Typography
            color="success.dark"
            variant="body2"
            component="span"
            mr={1}
          >
            Open
          </Typography>
          Closes at {nextOpenTime}
        </span>
      ) : (
        <span>{nextOpenTime}</span>
      )}
    </Box>
  );
};
