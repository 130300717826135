import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { map } from "lodash";
import Stack from "@mui/material/Stack";
import { useResponsive } from "../../../core/hooks/useResponsive";

interface Props {
  timeFrom?: string;
  timeTo?: string;
  setFromChange?: (value: number) => void;
  setToChange?: (value: number) => void;
  disabled?: boolean;
}

const times = {
  "01:00": 1,
  "02:00": 2,
  "03:00": 3,
  "04:00": 4,
  "05:00": 5,
  "06:00": 6,
  "07:00": 7,
  "08:00": 8,
  "09:00": 9,
  "10:00": 10,
  "11:00": 11,
  "12:00": 12,
  "13:00": 13,
  "14:00": 14,
  "15:00": 15,
  "16:00": 16,
  "17:00": 17,
  "18:00": 18,
  "19:00": 19,
  "20:00": 20,
  "21:00": 21,
  "22:00": 22,
  "23:00": 23,
  "24:00": 24,
};

const formatTime = (time: string) => {
  const [hour, minute] = time.split(":");
  const isPM = parseInt(hour, 10) >= 12;
  const formattedHour = isPM ? parseInt(hour, 10) - 12 : hour;
  const formattedMinute = minute.padStart(2, "0");
  const period = isPM ? "PM" : "AM";
  return `${formattedHour}:${formattedMinute} ${period}`;
};

export const FieldFromToTime: React.FC<Props> = ({
  timeFrom,
  timeTo,
  setFromChange,
  setToChange,
  disabled,
}) => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const { mobileValue } = useResponsive();

  useEffect(() => {
    if (timeFrom && timeTo) {
      const formattedTimeFrom =
        timeFrom.substr(0, 2) + ":" + timeFrom.substr(2);
      const formattedTimeTo = timeTo.substr(0, 2) + ":" + timeTo.substr(2);
      setFrom(formattedTimeFrom);
      setTo(formattedTimeTo);
    }
  }, [timeFrom, timeTo]);

  const handleFromChange = (event: any) => {
    const value = event.target.value as string;
    setFrom(value);
    if (value === "00:00") {
      setTo(null);
    }
    if (setFromChange) setFromChange(times[value]);
  };

  const handleToChange = (event: any) => {
    const value = event.target.value as string;
    setTo(value);
    if (setToChange) setToChange(times[value]);
  };

  const minWidth = mobileValue("auto", "150px");

  return (
    <Stack
      direction={mobileValue("column", "row")}
      spacing={3}
      width={mobileValue("100%", "auto")}
    >
      <TextField
        select
        label="From"
        size="small"
        value={from}
        onChange={handleFromChange}
        sx={{ minWidth }}
        disabled={disabled}
      >
        {map(times, (value, time) => (
          <MenuItem key={time} value={time} disabled={value >= times[to]}>
            {formatTime(time)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="To"
        size="small"
        value={to}
        onChange={handleToChange}
        sx={{ minWidth }}
        disabled={disabled}
      >
        {map(times, (value, time) => (
          <MenuItem key={time} value={time} disabled={value <= times[from]}>
            {formatTime(time)}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};
