import TextTruncate from "react-text-truncate";
import { Box } from "@mui/material";

function formatPhoneNumber(input) {
  input = input.replace(/\D/g, "");
  input = input.substring(0, 10);
  let size = input.length;
  if (size > 0) {
    if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10);
    }
  }
  return input;
}

export const placeColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    cellProps: {
      width: 100,
    },
    render: (text) => (
      <Box width={88}>
        <TextTruncate line={1} truncateText="…" text={text} />
      </Box>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    cellProps: {
      width: 200,
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    cellProps: {
      width: 200,
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    cellProps: {
      width: 200,
    },
    render: (text) => <TextTruncate line={1} truncateText="…" text={text} />,
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
    cellProps: {
      width: 147,
    },
    render: (phoneNumber) => formatPhoneNumber(phoneNumber) || "-",
  },
  {
    title: "Commitments",
    dataIndex: "commitments",
    key: "commitments",
    cellProps: {
      width: 90,
    },
  },
  {
    title: "IsFeatured",
    dataIndex: "isFeatured",
    key: "isFeatured",
    cellProps: {
      width: 90,
    },
    render: (text) => (text ? "Yes" : "No"),
  },
];
