import { LabeledList } from "../../../components/labeled-list";
import { formatToDate } from "../../../core/utils/date.utils";

export const Detail = ({ payload }) => {
  return (
    <>
      <LabeledList
        mb={3}
        items={[
          {
            label: "ID",
            value: payload?.id || "-",
          },
          {
            label: "Name",
            value: [payload?.firstName, payload?.lastName].join(" ") || "-",
          },
          {
            label: "Gender",
            value: payload?.gender?.name || "-",
          },
          {
            label: "Phone",
            value: payload?.phoneNumber || "-",
          },
          {
            label: "Bio",
            value: payload?.bio || "-",
          },
          {
            label: "Communities",
            value: payload?.communities || "-",
          },
          {
            label: "Created",
            value: formatToDate(payload?.createdAt) || "-",
          },
          {
            label: "Updated",
            value: formatToDate(payload?.updatedAt) || "-",
          },

          {
            label: "Last login",
            value: formatToDate(payload?.lastLogin) || "-",
          },
        ]}
      />
    </>
  );
};
