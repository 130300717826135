import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "base-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: "100vh",
      display: "flex",
      backgroundColor: "#fff",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        overflow: "auto",
      },
    },
    main: {
      padding: theme.spacing(0, 3, 4, 3),
      flexGrow: 1,
      [theme.breakpoints.down("md")]: {
        paddingTop: 48,
        padding: theme.spacing(6, 2, 3, 2),
      },
    },
    header: {},
  }),
  options
);

export default useStyles;
