import React, { ComponentType } from 'react';
import {
  MenuItem,
  TextField,
  TextFieldProps,
  SelectChangeEvent
} from '@mui/material';
import { IconArrowDown } from '../../icons';

interface ItemType {
  label: any;
  value: any;
}

export interface SelectFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  onChange?: (value: any) => void;
  items: ItemType[];
  multiple?: boolean;
  helperText?: string | null | any;
  errors?: any;
  value?: any;
  loading?: boolean;
}

export const FieldSelect: ComponentType<SelectFieldProps> = ({
  items,
  onChange,
  label,
  value = null,
  placeholder,
  errors,
  loading,
  ...props
}) => {
  const handleChange = (e: SelectChangeEvent) => {
    if (typeof e.target.value === 'undefined') return;
    onChange && onChange(e.target.value);
  };

  return (
    <TextField
      select
      error={!!errors}
      helperText={errors || props.helperText}
      value={value}
      disabled={loading}
      {...{ label, ...props }}
      SelectProps={{
        IconComponent: IconArrowDown,
        displayEmpty: true,
        onChange: handleChange
      }}
    >
      {loading && <MenuItem value="">Loading...</MenuItem>}
      {!loading && placeholder && <MenuItem value="">{placeholder}</MenuItem>}
      {items.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
