import { Grid, TextField, Box, Stack, Button } from "@mui/material";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect } from "react";
import { Loading } from "../../../components/loading";
import { useParams } from "react-router-dom";
import {
  useGetPlaceTokenByIdQuery,
  useUpdatePlaceTokenByIdMutation,
} from "../../../core/store/api";
import { get } from "lodash";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

export const Tokens = () => {
  const { id } = useParams();
  const { showMessage } = useSnackbar();

  const { data: tokenData, refetch } = useGetPlaceTokenByIdQuery({
    id,
  });
  const [updatePlaceTokenById, { isLoading: isUpdatePlaceTokenByIdLoading }] =
    useUpdatePlaceTokenByIdMutation();

  const { handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues: {
      id: tokenData?.id?.uuid || uuidv4(),
      placeId: tokenData?.placeId || "",
      description: tokenData?.description || "",
      preview: tokenData?.preview || "",
    },
    onSubmit: async (values) => {
      const res = await updatePlaceTokenById({ ...values, placeId: id });
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        showMessage(message, "error");
      } else {
        refetch();
        if (!values.description && !values.preview) {
          setFieldValue("id", uuidv4());
        }
        showMessage("Token has been updated successfully.", "success");
      }
    },
  });

  useEffect(() => {
    setFieldValue("description", tokenData?.description);
    setFieldValue("preview", tokenData?.preview);
  }, [tokenData]);

  if (isUpdatePlaceTokenByIdLoading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container columnSpacing={2} mb={5}>
        <Grid item lg={4} md={6} xs={12}>
          <Box mb={3}>
            <TextField
              label="Preview description"
              size="small"
              {...getFieldProps("preview")}
              onChange={(e) => {
                setFieldValue("preview", e.target.value);
              }}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Token description"
              size="small"
              {...getFieldProps("description")}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => handleSubmit()}>Update</Button>
            <Button
              onClick={() => {
                setFieldValue("description", "");
                setFieldValue("preview", "");
              }}
              variant="outlined"
              color="secondary"
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
