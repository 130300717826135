import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "snackbar",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: "fixed",
      top: 11,
      left: "calc(50% - 200px)",
      zIndex: 9999,

      "& .MuiPaper-root": {
        minWidth: 400,
        borderRadius: 8,
        padding: theme.spacing(1.5, 2),
      },

      "& .MuiAlert-icon": {
        padding: 0,
        opacity: 1,
      },

      "& .MuiAlert-message": {
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: "2px 0 0",
      },

      "& .MuiAlert-standardInfo": {
        backgroundColor: "#fff",
      },

      "& .MuiAlert-standardError": {
        backgroundColor: "#FFEAEA",

        "& .MuiSvgIcon-root": {
          color: "#FE8E8E",
        },
      },

      "& .MuiAlert-standardSuccess": {
        backgroundColor: "#E9FCD4",

        "& .MuiAlert-message": {
          color: "#08660D",
        },

        "& .MuiSvgIcon-root": {
          color: "#54D62C",
        },
      },

      "& .MuiAlert-standardWarning": {
        backgroundColor: "#fceed0",

        "& .MuiSvgIcon-root": {
          color: "#fdd77b",
        },
      },
    },
  }),
  options
);

export default useStyles;
