import { Exclude, Expose, Transform } from "class-transformer";
import { formatToDate } from "../utils/date.utils";

@Exclude()
export class NotificationDto {
  @Expose()
  id: string;

  @Expose()
  text: string;

  @Expose()
  @Transform(({ obj }) => obj.isSent === "1")
  isSent: boolean;

  @Expose()
  @Transform(({ obj }) => (obj.isSent ? "Yes" : "No"))
  isSentFormatted: string;

  @Expose()
  createdAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.createdAt))
  createdAtFormatted: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.updatedAt))
  updatedAtFormatted: string;
}
