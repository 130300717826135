import React from "react";
import { PageHeader } from "../../components/page-header";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTE_PARTIES_CREATE } from "../../core";
import { Tabs } from "../../components/tabs";
import { PartyTabListContent } from "./TabListContent";

export const PartyListContainer = () => {
  return (
    <>
      <PageHeader title="Parties" divider={false} withTabs>
        <Button variant="contained" component={Link} to={ROUTE_PARTIES_CREATE}>
          Add new
        </Button>
      </PageHeader>

      <Tabs
        hashed
        items={[
          {
            label: "Upcoming",
            key: "upcoming",
            content: <PartyTabListContent />,
          },
          {
            label: "Archive",
            key: "archive",
            content: <PartyTabListContent />,
          },
        ]}
      />
    </>
  );
};
