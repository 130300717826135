import Box from "@mui/material/Box";
import React, { ComponentType, PropsWithChildren } from "react";

interface Props {
  value: number;
  index: number;
}

export const TabPanel: ComponentType<PropsWithChildren<Props>> = ({
  value,
  index,
  children,
}: any) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};
