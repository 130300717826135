import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  labelGrey: {
    "& .MuiFormLabel-root": {
      backgroundColor: "#F4F6F8",
    },
  },
}));

export default useStyles;
