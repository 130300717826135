import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { GET_COUNTERS } from "../../http/api.routes";

interface ICounterResponse {
  usersCount: number;
  placesCount: number;
}

export const counterApi = createApi({
  reducerPath: "counterApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getCounters: builder.query<ICounterResponse, any>({
      query: () => ({
        url: GET_COUNTERS,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetCountersQuery } = counterApi;
