import React, { FC } from "react";
import { Drawer, Toolbar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { ROUTE_DASHBOARD } from "../../../core";
import { useResponsive } from "../../../core/hooks/useResponsive";
import { Nav } from "../nav/Nav";

interface SidebarProps {
  width: number;
}

export const Sidebar: FC<SidebarProps> = ({ width }) => {
  const classes = useStyles();
  const { isDesktop } = useResponsive();

  const drawer = (
    <>
      <Toolbar className={classes.toolbar}>
        <Link to={ROUTE_DASHBOARD} className={classes.toolbarLink}>
          {isDesktop ? (
            <img
              className={classes.logoImg}
              src={"/assets/images/sidebar-logo.svg"}
              alt="WYA"
            />
          ) : (
            <img
              className={classes.logoImg}
              src={"/assets/images/sidebar-logo-min.svg"}
              alt="WYA"
            />
          )}
        </Link>
      </Toolbar>
      <Nav />
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className={classes.root}
    >
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: width },
        }}
        open
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
