import { Box } from "@mui/system";
import { ComponentType, ReactNode } from "react";
import useStyles from "./styles";

interface Props {
  label: ReactNode;
  value: ReactNode;
  direction?: string;
}

export const LabeledRecord: ComponentType<Props> = ({
  label,
  value,
  direction = "column",
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      sx={{ display: "flex", flexDirection: direction }}
    >
      <Box
        className={classes.label}
        sx={{ marginRight: direction === "row" ? "8px" : 0 }}
      >
        {label}
      </Box>
      <Box className={classes.value}>{value}</Box>
    </Box>
  );
};
