export const ROUTE_HOME = "/";
export const ROUTE_SIGN_IN = "/sign-in";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_PLACES = "/places";
export const ROUTE_PLACES_DETAIL = "/places/view/:id";
export const ROUTE_PLACES_CREATE = "/places/create";
export const ROUTE_PLACES_EDIT = "/places/edit/:id";

export const ROUTE_USERS = "/users";
export const ROUTE_USERS_DETAIL = "/users/view/:id";

export const ROUTE_PARTIES = "/parties";
export const ROUTE_PARTIES_DETAIL = "/parties/view/:id";
export const ROUTE_PARTIES_CREATE = "/parties/create";
export const ROUTE_PARTIES_EDIT = "/parties/edit/:id";

export const ROUTE_PROMO_CODES = "/promo-codes";
export const ROUTE_NOTIFICATIONS = "/notifications";
export const ROUTE_NOTIFICATIONS_CREATE = "/notifications/create";
export const ROUTE_NOTIFICATIONS_EDIT = "/notifications/edit/:id";

export const ROUTE_PROFILE = "/profile";
export const ROUTE_PROFILE_EDIT = "/profile/edit";
