export const placesData = [
  {
    id: "6109107c02f0dbb01dc4cc4c",
    name: "Tasty Thai",
    category: "Thai",
    address: "123 Main St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5555",
    commitments: 72,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc4d",
    name: "Mario's Pizza",
    category: "Italian",
    address: "456 Elm St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5556",
    commitments: 43,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc4e",
    name: "Burger Joint",
    category: "American",
    address: "789 Oak St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5557",
    commitments: 85,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc4f",
    name: "Sushi Palace",
    category: "Japanese",
    address: "321 Pine St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5558",
    commitments: 67,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc50",
    name: "Mexican Grill",
    category: "Mexican",
    address: "654 Birch St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5559",
    commitments: 23,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc51",
    name: "Chinese Garden",
    category: "Chinese",
    address: "987 Maple St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5560",
    commitments: 78,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc52",
    name: "Pasta House",
    category: "Italian",
    address: "135 Walnut St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5561",
    commitments: 54,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "6109107c02f0dbb01dc4cc53",
    name: "BBQ Pit",
    category: "American",
    address: "246 Cedar St. Anytown, CA 90210",
    phoneNumber: "(555) 555-5562",
    commitments: 91,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e731",
    name: "The Hungry Bear",
    category: "American",
    address: "8502 Preston Rd. Inglewood, Maine 98380",
    phoneNumber: "(684) 555-0102",
    commitments: 62,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e732",
    name: "La Dolce Vita",
    category: "Italian",
    address: "4829 Shingleton Road, Grand Rapids, Michigan 49503",
    phoneNumber: "(616) 555-0128",
    commitments: 79,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e733",
    name: "Sushi Yama",
    category: "Japanese",
    address: "1234 W 7th St. Los Angeles, California 90017",
    phoneNumber: "(213) 555-0186",
    commitments: 35,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e734",
    name: "El Jefe's Taco Truck",
    category: "Mexican",
    address: "123 Main St. San Diego, California 92101",
    phoneNumber: "(619) 555-0132",
    commitments: 22,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e735",
    name: "Bistro 46",
    category: "French",
    address: "46 High St. Portland, Maine 04101",
    phoneNumber: "(207) 555-0123",
    commitments: 89,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e736",
    name: "Himalayan Spice",
    category: "Indian/Nepalese",
    address: "1234 Cedar Ave. Minneapolis, Minnesota 55454",
    phoneNumber: "(612) 555-0193",
    commitments: 47,
    isFeatured: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: "615f2b17d4a3b0e6f4c6e737",
    name: "Sushi King",
    category: "Japanese",
    address: "5678 Elm St. Chicago, Illinois 60610",
    phoneNumber: "(312) 555-0158",
    commitments: 61,
    isFeatured: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
];

export const workingHours = [
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 1,
  },
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 2,
  },
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 3,
  },
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 4,
  },
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 5,
  },
  {
    isOvernight: true,
    start: "2200",
    end: "0500",
    day: 6,
  },
  {
    isOvernight: false,
    start: "2200",
    end: "0500",
    day: 0,
  },
];

export const usersData = [
  {
    id: 1001,
    firstName: "Alice",
    lastName: "Smith",
    gender: "Female",
    phoneNumber: "(684) 555-0102",
    friends: 27,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-24T12:26:30.480Z",
    createdAt: "2023-02-24T12:26:30.480Z",
    updatedAt: "2023-02-24T12:26:30.480Z",
  },
  {
    id: 1002,
    firstName: "Bob",
    lastName: "Johnson",
    gender: "Male",
    phoneNumber: "(684) 555-0203",
    friends: 345,
    isBanned: 1,
    isDeleted: 0,
    lastLogin: "2023-02-23T09:10:15.620Z",
    createdAt: "2023-02-23T09:10:15.620Z",
    updatedAt: "2023-02-23T09:10:15.620Z",
  },
  {
    id: 1003,
    firstName: "Charlie",
    lastName: "Lee",
    gender: "Male",
    phoneNumber: "(684) 555-0304",
    friends: 5,
    isBanned: 0,
    isDeleted: 1,
    lastLogin: "2023-02-22T14:44:59.012Z",
    createdAt: "2023-02-22T14:44:59.012Z",
    updatedAt: "2023-02-22T14:44:59.012Z",
  },
  {
    id: 1004,
    firstName: "David",
    lastName: "Garcia",
    gender: "Male",
    phoneNumber: "(684) 555-0405",
    friends: 412,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-21T21:30:05.123Z",
    createdAt: "2023-02-21T21:30:05.123Z",
    updatedAt: "2023-02-21T21:30:05.123Z",
  },
  {
    id: 1005,
    firstName: "Emily",
    lastName: "Davis",
    gender: "Female",
    phoneNumber: "(684) 555-0506",
    friends: 84,
    isBanned: 1,
    isDeleted: 1,
    lastLogin: "2023-02-20T16:15:51.789Z",
    createdAt: "2023-02-20T16:15:51.789Z",
    updatedAt: "2023-02-20T16:15:51.789Z",
  },
  {
    id: 1006,
    firstName: "Frank",
    lastName: "Wilson",
    gender: "Male",
    phoneNumber: "(684) 555-0607",
    friends: 213,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-19T12:59:32.563Z",
    createdAt: "2023-02-19T12:59:32.563Z",
    updatedAt: "2023-02-19T12:59:32.563Z",
  },
  {
    id: 2345,
    firstName: "John",
    lastName: "Doe",
    gender: "Male",
    phoneNumber: "(684) 555-2345",
    friends: 52,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-24T15:02:41.000Z",
    createdAt: "2023-02-24T15:02:41.000Z",
    updatedAt: "2023-02-24T15:02:41.000Z",
  },
  {
    id: 5678,
    firstName: "Jane",
    lastName: "Johnson",
    gender: "Female",
    phoneNumber: "(684) 555-5678",
    friends: 189,
    isBanned: 1,
    isDeleted: 0,
    lastLogin: "2023-02-24T15:02:41.000Z",
    createdAt: "2023-02-24T15:02:41.000Z",
    updatedAt: "2023-02-24T15:02:41.000Z",
  },
  {
    id: 8910,
    firstName: "Michael",
    lastName: "Brown",
    gender: "Male",
    phoneNumber: "(684) 555-8910",
    friends: 670,
    isBanned: 0,
    isDeleted: 1,
    lastLogin: "2023-02-24T15:02:41.000Z",
    createdAt: "2023-02-24T15:02:41.000Z",
    updatedAt: "2023-02-24T15:02:41.000Z",
  },
  {
    id: 1234,
    firstName: "Avery",
    lastName: "Wilson",
    gender: "Female",
    phoneNumber: "(684) 555-1234",
    friends: 45,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-24T15:02:41.000Z",
    createdAt: "2023-02-24T15:02:41.000Z",
    updatedAt: "2023-02-24T15:02:41.000Z",
  },
  {
    id: 4567,
    firstName: "Eric",
    lastName: "Clark",
    gender: "Male",
    phoneNumber: "(684) 555-4567",
    friends: 827,
    isBanned: 1,
    isDeleted: 1,
    lastLogin: "2023-02-24T15:02:41.000Z",
    createdAt: "2023-02-24T15:02:41.000Z",
    updatedAt: "2023-02-24T15:02:41.000Z",
  },
  {
    id: 8765,
    firstName: "Emily",
    lastName: "Davis",
    gender: "Female",
    phoneNumber: "(684) 555-8765",
    friends: 129,
    isBanned: 0,
    isDeleted: 0,
    lastLogin: "2023-02-24T18:20:31.000Z",
    createdAt: "2023-02-24T18:20:31.000Z",
    updatedAt: "2023-02-24T18:20:31.000Z",
  },
  {
    id: 5432,
    firstName: "William",
    lastName: "Jones",
    gender: "Male",
    phoneNumber: "(684) 555-5432",
    friends: 923,
    isBanned: 1,
    isDeleted: 0,
    lastLogin: "2023-02-24T18:20:31.000Z",
    createdAt: "2023-02-24T18:20:31.000Z",
    updatedAt: "2023-02-24T18:20:31.000Z",
  },
  {
    id: 1098,
    firstName: "Maria",
    lastName: "Garcia",
    gender: "Female",
    phoneNumber: "(684) 555-1098",
    friends: 367,
    isBanned: 0,
    isDeleted: 1,
    lastLogin: "2023-02-24T18:20:31.000Z",
    createdAt: "2023-02-24T18:20:31.000Z",
    updatedAt: "2023-02-24T18:20:31.000Z",
  },
];

export const partiesData = [
  {
    id: 10001,
    name: "New Year Bash",
    host: {
      firstName: "John",
      lastName: "Doe",
    },
    startTime: "2023-01-01 20:00:00",
    endTime: "2023-01-02 04:00:00",
    address: "123 Main St. San Francisco, California 94111",
    location: "San Francisco, CA",
    invitedCount: 22,
    attendedCount: 33,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10002,
    name: "Pool Party",
    host: {
      firstName: "Jane",
      lastName: "Doe",
    },
    startTime: "2023-07-01 14:00:00",
    endTime: "2023-07-01 20:00:00",
    address: "456 Beach Blvd. Los Angeles, California 90210",
    location: "Los Angeles, CA",
    invitedCount: 44,
    attendedCount: 55,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10003,
    name: "Birthday Bash",
    host: {
      firstName: "Bob",
      lastName: "Smith",
    },
    startTime: "2023-03-15 19:00:00",
    endTime: "2023-03-16 01:00:00",
    address: "789 Park Ave. New York, New York 10021",
    location: "New York, NY",
    invitedCount: 23,
    attendedCount: 43,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10004,
    name: "New Workers",
    host: {
      firstName: "John",
      lastName: "Doe",
    },
    startTime: "2023-01-01 20:00:00",
    endTime: "2023-01-02 04:00:00",
    address: "123 Main St. San Francisco, California 94111",
    location: "San Francisco, CA",
    invitedCount: 22,
    attendedCount: 33,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10005,
    name: "NY Students",
    host: {
      firstName: "Jane",
      lastName: "Doe",
    },
    startTime: "2023-07-01 14:00:00",
    endTime: "2023-07-01 20:00:00",
    address: "456 Beach Blvd. Los Angeles, California 90210",
    location: "Los Angeles, CA",
    invitedCount: 44,
    attendedCount: 55,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10006,
    name: "Jerome Bell",
    host: {
      firstName: "Bob",
      lastName: "Smith",
    },
    startTime: "2023-03-15 19:00:00",
    endTime: "2023-03-16 01:00:00",
    address: "789 Park Ave. New York, New York 10021",
    location: "New York, NY",
    invitedCount: 23,
    attendedCount: 43,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10007,
    name: "New Year Bash",
    host: {
      firstName: "John",
      lastName: "Doe",
    },
    startTime: "2023-01-01 20:00:00",
    endTime: "2023-01-02 04:00:00",
    address: "123 Main St. San Francisco, California 94111",
    location: "San Francisco, CA",
    invitedCount: 22,
    attendedCount: 33,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10008,
    name: "WYA Friday",
    host: {
      firstName: "Jane",
      lastName: "Doe",
    },
    startTime: "2023-07-01 14:00:00",
    endTime: "2023-07-01 20:00:00",
    address: "456 Beach Blvd. Los Angeles, California 90210",
    location: "Los Angeles, CA",
    invitedCount: 44,
    attendedCount: 55,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10009,
    name: "Birthday Bash",
    host: {
      firstName: "Bob",
      lastName: "Smith",
    },
    startTime: "2023-03-15 19:00:00",
    endTime: "2023-03-16 01:00:00",
    address: "789 Park Ave. New York, New York 10021",
    location: "New York, NY",
    invitedCount: 23,
    attendedCount: 43,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10010,
    name: "First Pool Party’23",
    host: {
      firstName: "John",
      lastName: "Doe",
    },
    startTime: "2023-01-01 20:00:00",
    endTime: "2023-01-02 04:00:00",
    address: "123 Main St. San Francisco, California 94111",
    location: "San Francisco, CA",
    invitedCount: 22,
    attendedCount: 33,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10011,
    name: "Pool Party",
    host: {
      firstName: "Jane",
      lastName: "Doe",
    },
    startTime: "2023-07-01 14:00:00",
    endTime: "2023-07-01 20:00:00",
    address: "456 Beach Blvd. Los Angeles, California 90210",
    location: "Los Angeles, CA",
    invitedCount: 44,
    attendedCount: 55,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10012,
    name: "Birthday Bash",
    host: {
      firstName: "Bob",
      lastName: "Smith",
    },
    startTime: "2023-03-15 19:00:00",
    endTime: "2023-03-16 01:00:00",
    address: "789 Park Ave. New York, New York 10021",
    location: "New York, NY",
    invitedCount: 23,
    attendedCount: 43,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10013,
    name: "Pool Party",
    host: {
      firstName: "Jane",
      lastName: "Doe",
    },
    startTime: "2023-07-01 14:00:00",
    endTime: "2023-07-01 20:00:00",
    address: "456 Beach Blvd. Los Angeles, California 90210",
    location: "Los Angeles, CA",
    invitedCount: 44,
    attendedCount: 55,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
  {
    id: 10014,
    name: "Birthday Bash",
    host: {
      firstName: "Bob",
      lastName: "Smith",
    },
    startTime: "2023-03-15 19:00:00",
    endTime: "2023-03-16 01:00:00",
    address: "789 Park Ave. New York, New York 10021",
    location: "New York, NY",
    invitedCount: 23,
    attendedCount: 43,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget massa nec sapien",
    createdAt: "2022-12-01 12:00:00",
    updatedAt: "2023-01-01 08:00:00",
  },
];

export const notificationData = [
  {
    id: 1,
    text: "Raise a glass to the newest bar in town! [Bar Name] has arrived. Come check it out today!",
    location: "San Francisco, CA",
    recipientsCount: 5640,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 2,
    text: "Looking for a fresh drinking spot? [Bar Name] has opened its doors and is serving up delicious drinks. Come visit now!",
    location: "New York, NY",
    recipientsCount: 3123,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 3,
    text: "The wait is over! [Bar Name] is now open. Come enjoy a cold drink and good company at the newest bar in town.",
    location: "Los Angeles, CA",
    recipientsCount: 1234,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 4,
    text: "Introducing [Bar Name], a new bar that's sure to become your next go-to spot. Stop by for a drink today!",
    location: "San Francisco, CA",
    recipientsCount: 531,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 5,
    text: "Raise a glass to the newest bar in town! [Bar Name] has arrived. Come check it out today!",
    location: "San Francisco, CA",
    recipientsCount: 5640,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 6,
    text: "Looking for a fresh drinking spot? [Bar Name] has opened its doors and is serving up delicious drinks. Come visit now!",
    location: "New York, NY",
    recipientsCount: 3123,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 7,
    text: "The wait is over! [Bar Name] is now open. Come enjoy a cold drink and good company at the newest bar in town.",
    location: "Los Angeles, CA",
    recipientsCount: 1234,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 8,
    text: "Introducing [Bar Name], a new bar that's sure to become your next go-to spot. Stop by for a drink today!",
    location: "San Francisco, CA",
    recipientsCount: 531,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 9,
    text: "Raise a glass to the newest bar in town! [Bar Name] has arrived. Come check it out today!",
    location: "San Francisco, CA",
    recipientsCount: 5640,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 10,
    text: "Looking for a fresh drinking spot? [Bar Name] has opened its doors and is serving up delicious drinks. Come visit now!",
    location: "New York, NY",
    recipientsCount: 3123,
    isSent: true,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 11,
    text: "The wait is over! [Bar Name] is now open. Come enjoy a cold drink and good company at the newest bar in town.",
    location: "Los Angeles, CA",
    recipientsCount: 1234,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 12,
    text: "Introducing [Bar Name], a new bar that's sure to become your next go-to spot. Stop by for a drink today!",
    location: "San Francisco, CA",
    recipientsCount: 531,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 13,
    text: "The wait is over! [Bar Name] is now open. Come enjoy a cold drink and good company at the newest bar in town.",
    location: "Los Angeles, CA",
    recipientsCount: 1234,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
  {
    id: 14,
    text: "Introducing [Bar Name], a new bar that's sure to become your next go-to spot. Stop by for a drink today!",
    location: "San Francisco, CA",
    recipientsCount: 531,
    isSent: false,
    createdAt: "2022-12-01 12:00:00",
  },
];
