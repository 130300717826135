import { format } from "date-fns";
import { DISPLAY_DATE_FORMAT } from "../constants";

export const formatToDate = (date: string): string => {
  return date ? format(new Date(date), DISPLAY_DATE_FORMAT) : "";
};

export const transformTime = (time: number): string => {
  if (time < 10) {
    return `0${time * 100}`;
  }
  return (time * 100).toString();
};
