import { Autocomplete, TextField, Box, Chip, Avatar } from "@mui/material";
import React, { FC, useMemo } from "react";
import { keyBy, map } from "lodash";
import get from "lodash/get";
import Stack from "@mui/material/Stack";
import isEmpty from "lodash/isEmpty";

type Option = {
  label: string;
  value: string | number;
  avatar?: string;
};

interface Props {
  label?: string;
  placeholder?: string;
  options: Option[];
  onChange: (e: any) => any;
  value: any;
  size?: "small" | "medium";
  width?: number;
  helperText?: string;
  error?: boolean;
}
export const FieldUserSelect: FC<Props> = ({
  label,
  placeholder = "Select",
  options,
  onChange,
  value,
  width,
  helperText,
  error,
  ...props
}) => {
  const optionsByKey = useMemo(() => keyBy(options, "value"), [options]);

  const handleChange = (e, value, value2) => {
    const newValues = map(value, (x) => (x?.value ? x.value : x));
    onChange && onChange(newValues);
  };

  const handleDelete = (deleteValue) => () => {
    onChange && onChange(value.filter((x) => x !== deleteValue));
  };

  return (
    <Box>
      <Autocomplete
        multiple
        sx={{ maxWidth: width }}
        placeholder="Select"
        {...{ options, value, ...props }}
        getOptionLabel={(x: Option) => {
          return x.label;
        }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => {
          return option.value === value;
        }}
        renderTags={() => null}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Avatar
              src={option.avatar}
              sx={{
                width: 18,
                height: 18,
                marginRight: 1,
              }}
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ label, helperText, error }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              placeholder,
            }}
          />
        )}
      />
      {!isEmpty(value) && (
        <Stack direction="row" mt={1} gap={1.5} flexWrap="wrap">
          {map(value, (x) => {
            return (
              <Chip
                avatar={<Avatar src={get(optionsByKey, [x, "avatar"])} />}
                label={get(optionsByKey, [x, "label"])}
                size="small"
                onDelete={handleDelete(x)}
              />
            );
          })}
        </Stack>
      )}
    </Box>
  );
};
