import React, { useEffect, useMemo } from "react";
import { get, isEmpty, map } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageHeader } from "../../components/page-header";
import { Box, Grid, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { ROUTE_USERS_DETAIL, url, userColumns } from "../../core";
import { FieldSearch, FieldSelect } from "../../components/fields";
import { useResponsive } from "../../core/hooks/useResponsive";
import { LabeledRecord } from "../../components/labeled-record";
import { formatToDate } from "../../core/utils/date.utils";
import {
  useGetAdminLocationsQuery,
  useGetUsersQuery,
} from "../../core/store/api";
import { Loading } from "../../components/loading";
import useDebounce from "../../core/hooks/useDebounce";

export const UserListContainer = () => {
  const { mobileValue, isMobile } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const debouncedValue = useDebounce(searchParams.get("search"), 500);
  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );
  const pageValue = useMemo(() => searchParams.get("page"), [searchParams]);
  const { data, isFetching } = useGetUsersQuery(
    {
      locationId: locationValue,
      search: debouncedValue,
      page: pageValue || 1,
    },
    {
      skip: !locationValue,
    }
  );
  const { data: locationsData } = useGetAdminLocationsQuery({});

  const usersList = map(get(data, "items", []), (item) => ({
    ...item,
    id: item.id,
    name: item.firstName + " " + item.lastName,
    gender: item?.gender?.name,
    phoneNumber: item.phoneNumber,
    friends: item.friendsCount,
    isBanned: item.isBanned,
    isDeleted: item.isDeleted,
    lastLogin: item.lastLogin,
  }));

  const adminLocationsList = map(get(locationsData, "items", []), (item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const handleFilter = (key, value) => {
    if (key) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
  };

  const handlePageChange = (value) => {
    if (value) {
      searchParams.set("page", value);
    } else {
      searchParams.delete("page");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!locationValue && !isEmpty(adminLocationsList)) {
      handleFilter("location", adminLocationsList[0]?.value);
    }
  }, [locationValue, adminLocationsList]);

  useEffect(() => {
    if (!pageValue && pageValue === null) {
      handleFilter("page", data?.currentPage || 1);
    }
  }, []);

  const pagination = {
    page: data?.currentPage || 1,
    perPage: data?.recordsPerPage || 10,
    totalPages: data?.totalPages || 1,
    total: data?.totalPages || 1,
  };

  return (
    <>
      <PageHeader title="Users" divider />

      <Grid container spacing={2} mb={1.5}>
        <Grid item md={4} xs={12}>
          <FieldSelect
            label="Location"
            size="small"
            value={locationValue}
            items={adminLocationsList}
            onChange={(value) => handleFilter("location", value)}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <FieldSearch
            onChange={(value) => handleFilter("search", value)}
            size="small"
          />
        </Grid>
      </Grid>
      <Box mb={4.5}>
        <Typography variant="caption">
          Results: {data?.totalRecords || 0}
        </Typography>
      </Box>
      {isFetching ? (
        <Loading />
      ) : (
        <TableView
          hideColumns={mobileValue(
            [
              "id",
              "gender",
              "phoneNumber",
              "friends",
              "isBanned",
              "isDeleted",
              "lastLogin",
            ],
            []
          )}
          expandable={
            isMobile && {
              expandedRowRender: (record) => (
                <>
                  <LabeledRecord label="#" value={record.id} />
                  <LabeledRecord label="Gender" value={record.gender} />
                  <LabeledRecord
                    label="Phone number"
                    value={record.phoneNumber}
                  />
                  <LabeledRecord label="Friends" value={record.friends} />
                  <LabeledRecord
                    label="IsBanned"
                    value={record.isBanned ? "Yes" : "No"}
                  />
                  <LabeledRecord
                    label="IsDeleted"
                    value={record.isDeleted ? "Yes" : "No"}
                  />
                  <LabeledRecord
                    label="Last login"
                    value={formatToDate(record.lastLogin)}
                  />
                </>
              ),
              rowExpandable: () => isMobile,
            }
          }
          pagination={pagination}
          onChangePage={(value) => handlePageChange(value)}
          onRowClick={({ id }) =>
            navigate(
              url(`${ROUTE_USERS_DETAIL}?location=${locationValue}`, { id })
            )
          }
          dataSource={usersList}
          columns={userColumns}
          showEmptyState
        />
      )}
    </>
  );
};
