import useStyles from "./styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { IconError, IconSuccess } from "../../icons";
import { FC } from "react";

interface Props {
  variant: "success" | "error" | "warning" | "default";
  label: string;
  icon?: boolean;
}
export const StatusBadge: FC<Props> = ({ label, variant, icon }) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (variant) {
      case "success":
        return <IconSuccess className={classes.icon} />;
      case "error":
        return <IconError className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.default]: variant === "default",
        [classes.success]: variant === "success",
        [classes.warning]: variant === "warning",
        [classes.error]: variant === "error",
      })}
    >
      {icon && renderIcon()}
      {label}
    </Box>
  );
};
