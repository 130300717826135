import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "detail-header",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: 8,
      marginBottom: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    meta: {
      marginLeft: theme.spacing(1),
    },
    action: {
      marginLeft: "auto",
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      },
    },
  }),
  options
);

export default useStyles;
