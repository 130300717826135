import React, { FC } from "react";
import loaderSvg from "./loader.svg";
import { Box } from "@mui/system";
import { BoxProps } from "@mui/material/Box";

interface LoadingProps extends BoxProps {
  size?: "xs" | "small" | "medium" | "large";
}

export const Loading: FC<LoadingProps> = ({ size = "medium", ...props }) => {
  const getPadding = () => {
    switch (size) {
      case "xs":
        return 0;
      case "small":
        return 0.5;
      case "medium":
        return 5;
      case "large":
        return 10;
    }
  };

  return (
    <Box display="flex" p={getPadding()} justifyContent="center" {...props}>
      <img src={loaderSvg} alt="loader" width="50" />
    </Box>
  );
};
