export const attendedUserColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    cellProps: {
      width: 100,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, { firstName, lastName }) =>
      [firstName, lastName].filter(Boolean).join(" ") || "N/A",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    cellProps: {
      sx: {
        width: 90,
      },
    },
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    cellProps: {
      sx: {
        width: 200,
      },
    },
  },
];
