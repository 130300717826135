import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FieldSearch, FieldSelect } from "../../components/fields";
import { TableView } from "../../components/table-view";
import { partyColumns, ROUTE_PARTIES_DETAIL, url } from "../../core";
import { partiesData } from "../../core/_mockdata";
import { useNavigate } from "react-router-dom";
import { LabeledRecord } from "../../components/labeled-record";
import { useResponsive } from "../../core/hooks/useResponsive";
import { format } from "date-fns";
import { useGetAdminLocationsQuery } from "../../core/store/api";
import { get, isEmpty, map } from "lodash";

export const PartyTabListContent = () => {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const { mobileValue, isMobile } = useResponsive();
  const { data: locationsData } = useGetAdminLocationsQuery({});

  const adminLocationsList = map(get(locationsData, "items", []), (item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (!isEmpty(adminLocationsList)) {
      setSelectedLocation(adminLocationsList[0].value);
    }
  }, [adminLocationsList]);

  const handlePageChange = () => {};

  const pagination = {
    page: 1,
    perPage: 14,
    totalPages: 10,
    total: 140,
  };

  return (
    <>
      <Grid container spacing={2} mb={1.5}>
        <Grid item md={4} xs={12}>
          <FieldSelect
            label="Location"
            size="small"
            value={selectedLocation}
            items={adminLocationsList}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <FieldSearch onChange={(value) => {}} size="small" />
        </Grid>
      </Grid>

      <Box mb={4.5}>
        <Typography variant="caption">Results: 4,234</Typography>
      </Box>

      <TableView
        hideColumns={mobileValue(
          [
            "id",
            "host",
            "address",
            "startDate",
            "startTime",
            "endTime",
            "address",
          ],
          []
        )}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <LabeledRecord label="#" value={record.id} />
              <LabeledRecord
                label="Host"
                value={[record.firstName, record.lastName]
                  .filter(Boolean)
                  .join(" ")}
              />
              <LabeledRecord
                label="Date"
                value={format(new Date(record.startTime), "dd/MM/yyyy")}
              />
              <LabeledRecord
                label="Start Time"
                value={format(new Date(record.startTime), "hh:mm a")}
              />
              <LabeledRecord
                label="End Time"
                value={format(new Date(record.endTime), "hh:mm a")}
              />
              <LabeledRecord label="Address" value={record.address} />
            </>
          ),
          rowExpandable: () => isMobile,
        }}
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) => navigate(url(ROUTE_PARTIES_DETAIL, { id }))}
        dataSource={partiesData}
        columns={partyColumns}
        showEmptyState
      />
    </>
  );
};
