import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "stats-card",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(2, 3),
      borderRadius: 16,
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2),
      },
    },
    white: {
      backgroundColor: "#fff",
    },
    grey: {
      backgroundColor: "#F4F6F8",
    },
  }),
  options
);

export default useStyles;
