export const DISPLAY_DATE_FORMAT = "MM/dd/yyyy hh:mm aa";
export const DISPLAY_ONLY_DATE_FORMAT = "dd.MM.yyyy";

export const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
