import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "status-badge",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "inline-flex",
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "20px",
      padding: "1px 8px",
      textTransform: "capitalize",
      borderRadius: 6,
      alignItems: "center",
    },
    default: {
      backgroundColor: "rgba(145, 158, 171, 0.16);",
      color: "#637381",
    },
    success: {
      backgroundColor: "rgba(84, 214, 44, 0.16)",
      color: "#229A16",
    },
    warning: {
      backgroundColor: "rgba(255, 193, 7, 0.16)",
      color: "#B78103",
    },
    error: {
      backgroundColor: "rgba(255, 72, 66, 0.16)",
      color: "#B72136",
    },
    icon: {
      fontSize: "16px !important",
      marginRight: 2,
    },
  }),
  options
);

export default useStyles;
