import { Exclude, Expose, Transform } from "class-transformer";
import { formatToDate } from "../utils/date.utils";
import get from "lodash/get";

@Exclude()
export class ProfileDto {
  @Expose()
  id: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  email: string;

  @Expose()
  phoneNumber: string;

  @Expose()
  @Transform(
    ({ obj }) =>
      [get(obj, "firstName", ""), get(obj, "lastName", "")]
        .filter(Boolean)
        .join(" ") || "N/A"
  )
  fullName: string;

  @Expose()
  @Transform(({ obj }) =>
    [
      (get(obj, "firstName") || "").charAt(0),
      (get(obj, "lastName") || "").charAt(0),
    ].join("")
  )
  initials: string;

  @Expose()
  @Transform(() => "Admin")
  role: string;

  @Expose()
  createdAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.createdAt))
  createdAtFormatted: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.updatedAt))
  updatedAtFormatted: string;

  @Expose()
  lastLogin: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.lastLogin))
  lastLoginFormatted: string;
}
