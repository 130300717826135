import React, { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { FieldPhone } from "../../../components/fields";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { useResponsive } from "../../../core/hooks/useResponsive";

interface Props extends DialogProps {}

export const ChangePhoneDialog: ComponentType<Props> = ({ popDialog }) => {
  const { mobileValue } = useResponsive();
  const minWidth = mobileValue("90%", 440);
  const [step, setStep] = React.useState<"phone" | "code">("phone");
  const { showMessage } = useSnackbar();

  const handleSubmitPhone = () => {
    setStep("code");
  };

  const handleSubmitCode = () => {
    popDialog();
    showMessage("Phone number was changed successfully");
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { minWidth } }}
    >
      <DialogTitle>Change phone number</DialogTitle>

      {step === "phone" && (
        <>
          <DialogContent>
            <Typography variant="body2" mb={2}>
              Please enter new phone number below.
            </Typography>
            <Stack spacing={3} pt={1}>
              <FieldPhone label="Phone" size="small" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="outlined" onClick={popDialog}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmitPhone();
              }}
            >
              Send code
            </Button>
          </DialogActions>
        </>
      )}

      {step === "code" && (
        <>
          <DialogContent>
            <Typography variant="body2" mb={2}>
              Please enter the code below.
            </Typography>
            <Stack spacing={3} pt={1}>
              <TextField label="SMS code" size="small" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="outlined" onClick={popDialog}>
              Cancel
            </Button>
            <Button color="secondary" variant="outlined">
              Send again
            </Button>
            <Button
              onClick={() => {
                handleSubmitCode();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
