import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "sign-in-form",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      minWidth: 464,
      maxWidth: 464,
      boxShadow: "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
      padding: 32,

      "& input": {
        fontWeight: 900,
      },

      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
        margin: theme.spacing(0, 2),
      },
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 40,
    },
    title: {
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 900,
      marginBottom: 24,
    },
    logo: {
      width: 80,
      margin: "0 0 12px 0 ",
    },
    backButton: {
      position: "absolute",
      top: 50,
      left: 50,
      width: 48,
      height: 48,
      background: "rgba(255, 255, 255, 0.24)",
      borderRadius: "50%",

      [theme.breakpoints.down("md")]: {
        top: 20,
        left: 16,
      },
    },
  }),
  options
);

export default useStyles;
