import { ComponentType, ReactNode } from "react";
import { BoxProps } from "@mui/material/Box";
import { Box, Typography, Stack } from "@mui/material";
import useStyles from "./styles";
// import emptyStateOne from '../../assets/empty-state-one.png';

export interface EmptyStateProps extends BoxProps {
  image?: string;
  title?: string;
  description?: string;
  action?: ReactNode;
}

export const EmptyState: ComponentType<EmptyStateProps> = ({
  image,
  action,
  title,
  description,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box pt={10} {...props} className={classes.root}>
      <Stack alignItems="center" justifyContent="center" height="100%">
        <div className={classes.image}>
          {/*{image ? (*/}
          {/*  <img src={image} alt="Empty" />*/}
          {/*) : (*/}
          {/*  <img src={emptyStateOne} alt="Empty" />*/}
          {/*)}*/}
        </div>
        {(title || description) && (
          <div className={classes.content}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </div>
        )}
        {action && <div className={classes.action}>{action}</div>}
      </Stack>
    </Box>
  );
};
