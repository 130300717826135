import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import get from "lodash/get";
import { useUser } from "./useUser";
import { useUpdateProfileMutation } from "../store/api";

interface UseProps {
  onSuccess: () => void;
}

export const useProfileForm = ({ onSuccess }: UseProps) => {
  const { data } = useUser();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("Required field"),
      lastName: yup.string().required("Required field"),
      email: yup.string().email("Please enter valid email"),
    }),
    onSubmit: async ({ phoneNumber, ...values }) => {
      try {
        await updateProfile(values);
        onSuccess();
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    setValues({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
    });
  }, [setValues, data]);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    isLoading,
    handleSubmit,
    errors,
    values,
    getFieldProps,
    data,
  };
};
