import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "sidebar",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: "none",
    },
    toolbar: {
      padding: theme.spacing(2.5, 3.25),
      marginBottom: theme.spacing(1.5),
      borderBottom: "1px dashed rgba(255, 255, 255, 0.24)",
    },
    toolbarLink: {
      lineHeight: 1,
      textDecoration: "none",
    },
    drawer: {},
    drawerPaper: {
      borderRadius: 0,
      background: `url(/assets/images/sidebar-bg.jpg) no-repeat top center`,
      backgroundSize: "cover",
      color: theme.palette.primary.contrastText,
      border: "none",
    },
    nav: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      margin: theme.spacing(0, 2),
    },
    navItem: {
      padding: theme.spacing(1.625, 1.5, 1.625, 2),
      borderRadius: 8,
      marginBottom: theme.spacing(0.5),
      "& svg": {
        color: theme.palette.common.white,
      },
    },
    navLabel: {
      marginLeft: "auto",
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
      padding: "1px 8px",
      borderRadius: 6,
      border: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
    navItemSelected: {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
    userNavItem: {
      marginTop: "auto",
      marginBottom: theme.spacing(3),
      color: "#fff",
      padding: theme.spacing(1.5, 2.5),
      borderRadius: 8,
      backgroundColor: theme.palette.secondary.light,
      [theme.breakpoints.down("lg")]: {
        padding: "12px 9px",
      },
    },
    navItemIcon: {
      display: "flex",
      marginRight: theme.spacing(2),
    },
    avatar: {
      width: 40,
      height: 40,
      fontSize: 14,
      fontWeight: 600,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    logoImg: {
      height: 32,
    },
  }),
  options
);

export default useStyles;
