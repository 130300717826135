import * as yup from "yup";
import { useFormik } from "formik";

import { useVerifyCodeMutation } from "../store/api";
import { get } from "lodash";

interface useVerifyCodeProps {
  onSuccess: (token: string) => void;
  onError: (message: string) => void;
}

export const useVerifyCode = ({ onSuccess, onError }: useVerifyCodeProps) => {
  const [verifyCode, { isLoading }] = useVerifyCodeMutation();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      phoneNumber: "",
      code: "",
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required("Required field"),
      code: yup
        .string()
        .min(6, "Code must be 6 characters")
        .max(6, "Code must be 6 characters")
        .required("Required field"),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        phoneNumber: `+${values.phoneNumber}`,
      };
      const res = await verifyCode(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.message", "Something went wrong");
        onError(message);
      } else {
        const data = get(res, "data", null);
        if (data) {
          onSuccess(data.accessToken);
        }
      }
    },
  });
  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
    };
  };

  return {
    handleSubmit,
    values,
    getFieldProps,
    setFieldValue,
    isLoading,
  };
};
