import useStyles from "./styles";
import { Box } from "@mui/system";
import { FC } from "react";

interface Props {
  src: string;
  alt?: string;
}
export const AdaptiveImage: FC<Props> = ({ src, alt }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img src={src} alt={alt} />
    </Box>
  );
};
