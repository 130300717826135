import { Button, Divider, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PROFILE } from "../../core";
import { PageHeader } from "../../components/page-header";
import React from "react";
import { ChangePhoneDialog } from "./dialogs/ChangePhoneDialog";
import { useDialog } from "../../core/hooks/useDialog";
import { FieldPhone } from "../../components/fields";
import { useProfileForm } from "../../core/hooks/useProfileForm";
import { useSnackbar } from "../../core/hooks/useSnackbar";

export const ProfileFormContainer = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [openChangePhone] = useDialog(ChangePhoneDialog);
  const { getFieldProps, handleSubmit, isLoading } = useProfileForm({
    onSuccess: () => {
      showMessage("Profile updated successfully");
    },
  });

  return (
    <>
      <PageHeader title="Edit profile">
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_PROFILE)}
        >
          Cancel
        </Button>
      </PageHeader>

      <Grid container spacing={2} mb={2.5}>
        <Grid item lg={4} xs={12} md={6}>
          <TextField
            label="First name"
            size="small"
            {...getFieldProps("firstName")}
          />
        </Grid>
        <Grid item lg={4} xs={12} md={6}>
          <TextField
            label="Last name"
            size="small"
            {...getFieldProps("lastName")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={3}>
        <Grid item lg={4} md={6} xs={12}>
          <TextField label="Email" size="small" {...getFieldProps("email")} />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 3, mx: -3 }} />
      <Grid container spacing={2}>
        <Grid item lg={4} md={6} xs={8}>
          <FieldPhone
            label="Phone"
            size="small"
            {...getFieldProps("phoneNumber")}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={2}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ padding: "7px 18px" }}
            onClick={openChangePhone}
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
