import React, { ComponentType, useState } from "react";
import Table, { TableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import { Box, Pagination } from "@mui/material";
import { TableCellProps } from "@mui/material/TableCell/TableCell";
import { TableViewRow } from "./TableViewRow";
import { TableViewHeader } from "./table-view-header";
import { useTableSelection } from "./useTableSelection";
import { useTableSorting } from "./useTableSorting";
import { useTableFiltering } from "./useTableFiltering";
import useStyles from "./styles";
import clsx from "clsx";
import map from "lodash/map";
import { TableViewLoadingRow } from "./TableViewLoadingRow";
import isEmpty from "lodash/isEmpty";
import { EmptyState } from "../empty-state";
import { EmptyStateProps } from "../empty-state/EmptyState";
import { times } from "lodash";
import { useResponsive } from "../../core/hooks/useResponsive";

export interface DataTableFilterItem {
  label: string;
  value: string;
}

export interface DataTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  cellProps?: TableCellProps;
  render?: (value: any, record: any, renderProps: any) => any;
  sortable?: boolean;
  filters?: DataTableFilterItem[];
}

export interface DataTableRowSelection {
  selectedRowKeys: string[];
  onChange: (item: string[]) => void;
}

export interface DataTableRowExpandable {
  expandedRowRender: (record: any) => any;
  rowExpandable?: (record: any) => boolean;
}

interface Props extends TableContainerProps {
  dataSource: Record<string, any>[];
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onRowClick?: (item: Record<string, any>) => void;
  expandable?: DataTableRowExpandable;
  hideColumns?: string[];
  striped?: boolean;
  renderProps?: any;
  pagination?: {
    page: number;
    perPage: number;
    totalPages: number;
    total: number;
  };
  showPagination?: boolean;
  showTablePerPage?: boolean;
  keyExtractor?: (item: Record<string, any>) => string;
  loading?: boolean;
  loadingSkeletonCount?: number;
  onChangePage?: (page: number) => void;
  onChangePerPage?: (perPage: number) => void;
  onChangeSort?: (sortState: Record<string, "desc" | "asc">) => void;
  showEmptyState?: boolean;
  EmptyStateProps?: EmptyStateProps;
  TableProps?: TableProps;
}

export const TableView: ComponentType<Props> = ({
  rowSelection,
  columns: defaultColumns,
  dataSource,
  onRowClick,
  expandable,
  hideColumns = [],
  striped,
  renderProps,
  showPagination = true,
  showTablePerPage = true,
  pagination,
  keyExtractor = (item) => item.id,
  loading,
  loadingSkeletonCount = 10,
  onChangePage,
  onChangePerPage,
  showEmptyState = false,
  EmptyStateProps = {},
  TableProps,
  onChangeSort,
  ...props
}) => {
  const { mobileValue } = useResponsive();
  const {
    selectedRows,
    isSelectedAll,
    handleSelectRow,
    isSelectedRowsEmpty,
    handleSelectAll,
  } = useTableSelection(dataSource, rowSelection, keyExtractor);

  const [expandedIsOpen, setExpandedIsOpen] = useState<number>(-1);

  const { sortingState, sort } = useTableSorting(onChangeSort);
  const { filteringState, filter, clearFilter } = useTableFiltering();

  const isSelectable = !!rowSelection;
  const columns = defaultColumns.filter(
    (column) => !hideColumns.includes(column.key)
  );
  const isExpandable = Boolean(expandable && !!expandable.expandedRowRender);
  const columnsCount =
    columns.length + (isSelectable ? 1 : 0) + (isExpandable ? 1 : 0);
  const classes = useStyles();

  if (!loading && isEmpty(dataSource) && showEmptyState) {
    return (
      <EmptyState
        title="No data found"
        description="Please, try to change filters"
        {...EmptyStateProps}
      />
    );
  }

  return (
    <>
      <TableContainer
        className={clsx(classes.root, { [classes.striped]: striped })}
        {...props}
      >
        <Table {...TableProps}>
          <TableViewHeader
            {...{
              loading,
              isExpandable,
              isSelectable,
              isSelectedAll,
              isSelectedRowsEmpty,
              handleSelectAll,
              columns,
              sortingState,
              sort,
              filteringState,
              filter,
              clearFilter,
            }}
          />
          <TableBody>
            {loading
              ? times(loadingSkeletonCount, (item) => (
                  <TableViewLoadingRow
                    key={`skeleton-${item}`}
                    isSelectable={isSelectable}
                    columns={columns}
                  />
                ))
              : map(dataSource, (item) => (
                  <TableViewRow
                    key={keyExtractor(item)}
                    onClick={(item) => onRowClick && onRowClick(item)}
                    {...{
                      keyExtractor,
                      expandable,
                      columnsCount,
                      item,
                      columns,
                      rowSelection,
                      selectedRows,
                      setSelectedRows: handleSelectRow,
                      renderProps,
                      expandedIsOpen,
                      setExpandedIsOpen,
                    }}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && !!dataSource && (
        <Box mt={2}>
          <Pagination
            boundaryCount={mobileValue(0, 1)}
            page={pagination.page}
            count={pagination.total}
            onChange={(_, page) => onChangePage && onChangePage(page)}
          />
        </Box>
      )}
    </>
  );
};
