import { ComponentType, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogProps } from "../../core/providers/dialog.provider";
import { Typography, Button } from "@mui/material";
import { useResponsive } from "../../core/hooks/useResponsive";

interface Props extends DialogProps {
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  body?: ReactNode;
}

export const ConfirmDialog: ComponentType<Props> = ({
  popDialog,
  title = "Confirm your action",
  message,
  onConfirm,
  okText = "Confirm",
  cancelText = "Cancel",
  body,
}) => {
  const { mobileValue } = useResponsive();

  const minWidth = mobileValue("90%", 440);

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { minWidth } }}
    >
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <Typography variant="body2">{message}</Typography>
          {body && body}
        </DialogContent>
      )}
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={popDialog}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm}>{okText}</Button>
      </DialogActions>
    </Dialog>
  );
};
