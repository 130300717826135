import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import {
  GET_USERS,
  GET_USER_BY_ID,
  BAN_USER,
  UNBAN_USER,
  DELETE_USER,
} from "../../http/api.routes";
import { url } from "../../utils";

interface IGetUsersResponse {
  items: IUser[];
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  friendCount: number;
  isBanned: boolean;
  isDeleted: boolean;
  lastLogin: string;
}

interface IGetUserByIdResponse {
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  gender: string;
  phoneNumber: string;
  bio: string;
  communities: string;
  createdAt: string;
  updatedAt: string;
  lastLogin: string;
  isBanned: boolean;
  isDeleted: boolean;
}

interface IUserActionsRequest {
  userId: string;
}

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getUsers: builder.query<IGetUsersResponse, any>({
      query: (params) => ({
        url: GET_USERS,
        method: "GET",
        params,
      }),
    }),
    getUserById: builder.query<IGetUserByIdResponse, any>({
      query: (params) => ({
        url: url(GET_USER_BY_ID, { id: params.id }),
        method: "GET",
        params,
      }),
    }),
    banUser: builder.mutation<IUserActionsRequest, IUserActionsRequest>({
      query: (params) => ({
        url: BAN_USER,
        method: "POST",
        body: params,
      }),
    }),
    unbanUser: builder.mutation<IUserActionsRequest, any>({
      query: (params) => ({
        url: UNBAN_USER,
        method: "POST",
        body: params,
      }),
    }),
    deleteUser: builder.mutation<IUserActionsRequest, any>({
      query: (params) => ({
        url: url(DELETE_USER, { userId: params.userId }),
        method: "DELETE",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useBanUserMutation,
  useUnbanUserMutation,
  useDeleteUserMutation,
} = usersApi;
