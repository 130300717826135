import React, { FC, PropsWithChildren } from "react";
import { Box } from "@mui/material";
import useStyles from "./styles";
import { Loading } from "../../loading";

export const LoadingLayout: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box component="main" className={classes.root}>
        <Box className={classes.container}>
          <Loading />
        </Box>
      </Box>
    </>
  );
};
