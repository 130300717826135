import { Theme, useMediaQuery } from "@mui/material";

type ValueKeys = "isMobile" | "isTablet" | "isDesktop";

export const useResponsive = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("md")
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const mobileValue = (value: any, defaultValue: any) => {
    return isMobile ? value : defaultValue;
  };

  const tabletAndMobileValue = (value: any, defaultValue: any) => {
    return isTablet || isMobile ? value : defaultValue;
  };

  const responsiveValue = (values: Record<ValueKeys, any>) => {
    return isMobile
      ? values.isMobile
      : isTablet
      ? values.isTablet
      : values.isDesktop;
  };

  return {
    isMobile,
    isTablet,
    isDesktop,
    mobileValue,
    tabletAndMobileValue,
    responsiveValue,
  };
};
