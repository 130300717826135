import { FC, ReactNode } from "react";
import useStyles from "./styles";
import { Box, BoxProps, Typography } from "@mui/material";
import clsx from "clsx";
import { TypographyProps } from "@mui/material/Typography";
import { Loading } from "../loading";

interface Props extends BoxProps {
  title: string;
  value: ReactNode;
  variant?: "white" | "grey";
  variantValue?: TypographyProps["variant"];
  variantTitle?: TypographyProps["variant"];
  loading?: boolean;
}
export const StatsCard: FC<Props> = ({
  title,
  value,
  variant = "grey",
  variantValue = "h4",
  variantTitle = "subtitle2",
  loading,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.white]: variant === "white",
        [classes.grey]: variant === "grey",
      })}
      {...props}
    >
      {loading ? (
        <Loading size="small" />
      ) : (
        <>
          <Typography variant={variantTitle}>{title}</Typography>
          <Typography variant={variantValue} fontWeight={900}>
            {value}
          </Typography>
        </>
      )}
    </Box>
  );
};
