import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { GET_ADMIN_LOCATIONS } from "../../http/api.routes";

interface IGetAdminLocationsResponse {
  items: IGetAdminLocationsItem[];
}

interface IGetAdminLocationsItem {
  id: string;
  name: string;
  centerLatitude: string;
  centerLongitude: string;
  areas: IGetAdminLocationsArea[];
  zipCodes: string[];
}

interface IGetAdminLocationsArea {
  id: string;
  latitude: string;
  longitude: string;
  radius: number;
}

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAdminLocations: builder.query<IGetAdminLocationsResponse, any>({
      query: (params) => ({
        url: GET_ADMIN_LOCATIONS,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetAdminLocationsQuery } = locationApi;
