import { Box } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import useStyles from "./styles";
import { Sidebar } from "../sidebar";
import { useResponsive } from "../../../core/hooks/useResponsive";
import { Header } from "../header";

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();

  const { isDesktop, isMobile } = useResponsive();

  return (
    <Box className={classes.root}>
      {isMobile ? <Header /> : <Sidebar width={isDesktop ? 281 : 88} />}

      <SimpleBar className={classes.main}>{children}</SimpleBar>
    </Box>
  );
};
