import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "../../components/page-header";
import { Grid, Box } from "@mui/material";
import { StatsCard } from "../../components/stats-card";
import { formatNumber } from "../../core";
import { FieldSelect } from "../../components/fields";
import {
  useGetAdminLocationsQuery,
  useGetDashboardQuery,
} from "../../core/store/api";
import { get, isEmpty, map } from "lodash";

export const DashboardContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );
  const { data: locationsData } = useGetAdminLocationsQuery({});
  const { data, isFetching } = useGetDashboardQuery(
    {
      locationId: locationValue,
    },
    {
      skip: !locationValue,
    }
  );
  const adminLocationsList = useMemo(
    () =>
      map(get(locationsData, "items", []), (item) => ({
        ...item,
        value: item.id,
        label: item.name,
      })),
    [locationsData]
  );

  const handleFilterByLocation = (value) => {
    let search;
    if (value) {
      search = {
        location: value,
      };
    } else {
      search = undefined;
    }
    setSearchParams(search, { replace: true });
  };

  useEffect(() => {
    if (!locationValue && !isEmpty(adminLocationsList)) {
      handleFilterByLocation(adminLocationsList[0]?.value);
    }
  }, [locationValue, adminLocationsList]);

  return (
    <Box>
      <PageHeader title="Dashboard" />

      <Grid container spacing={2} mb={2}>
        <Grid item lg={4} md={6} xs={12}>
          <FieldSelect
            label="Location"
            size="small"
            value={locationValue}
            items={adminLocationsList}
            onChange={(value) => handleFilterByLocation(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item lg={2.4} md={4} xs={6}>
          <StatsCard
            title="New Users"
            value={formatNumber(data?.newUserCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={2.4} md={4} xs={6}>
          <StatsCard
            title="Users [today]"
            value={formatNumber(data?.todayUserCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={2.4} md={4} xs={6}>
          <StatsCard
            title="Users [week]"
            value={formatNumber(data?.weekUserCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={2.4} md={4} xs={6}>
          <StatsCard
            title="Users [month]"
            value={formatNumber(data?.monthUserCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={2.4} md={4} xs={6}>
          <StatsCard
            title="Total Users"
            value={formatNumber(data?.totalUserCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={6} md={4} xs={6}>
          <StatsCard
            title="Total Places"
            value={formatNumber(data?.totalPlaceCount)}
            loading={isFetching}
          />
        </Grid>
        <Grid item lg={6} md={4} xs={12}>
          <StatsCard
            title="Commitments"
            value={formatNumber(data?.totalCommitmentCount)}
            loading={isFetching}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
