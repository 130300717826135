import { combineReducers } from "redux";
import {
  signInApi,
  locationApi,
  placesApi,
  usersApi,
  profileApi,
  dashboardApi,
  counterApi,
} from "./api";

export const rootReducer = combineReducers({
  [signInApi.reducerPath]: signInApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [placesApi.reducerPath]: placesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [counterApi.reducerPath]: counterApi.reducer,
});
