import { Avatar, Box, List, ListItem, Typography } from "@mui/material";
import { map, startsWith } from "lodash";
import { NavLink, useLocation } from "react-router-dom";
import {
  ROUTE_DASHBOARD,
  ROUTE_PLACES,
  ROUTE_PROFILE,
  ROUTE_USERS,
} from "../../../core";
import useStyles from "./styles";
import { useUser } from "../../../core/hooks/useUser";
import { useResponsive } from "../../../core/hooks/useResponsive";
import { IconDashboard, IconPlaces, IconUsers } from "../../icons";
import clsx from "clsx";
import { useGetCountersQuery } from "../../../core/store/api";
import { useEffect } from "react";

const NAV_ITEMS = ({ usersTotal, placesTotal }) => [
  {
    label: "Dashboard",
    path: ROUTE_DASHBOARD,
    icon: <IconDashboard />,
    sx: null,
  },
  {
    label: "Places",
    path: ROUTE_PLACES,
    icon: <IconPlaces />,
    sx: null,
    counter: placesTotal,
  },
  {
    label: "Users",
    path: ROUTE_USERS,
    icon: <IconUsers />,
    sx: null,
    counter: usersTotal,
  },
];

export const Nav = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { data, isLoading } = useUser();
  const { isTablet } = useResponsive();
  const { data: countersData, refetch } = useGetCountersQuery({});

  useEffect(() => {
    const id = setInterval(() => {
      refetch();
    }, 30000);
    return () => clearInterval(id);
  }, []);

  const isSelected = (path: string) => {
    return startsWith(pathname, path);
  };

  const ListItemProps = (path: string): any => {
    return {
      button: true,
      selected: isSelected(path),
      classes: {
        root: classes.navItem,
        selected: classes.navItemSelected,
      },
      component: path ? NavLink : null,
      to: path,
    };
  };

  return (
    <List className={clsx(classes.nav, "nav")} disablePadding component="div">
      {map(
        NAV_ITEMS({
          usersTotal: countersData?.usersCount,
          placesTotal: countersData?.placesCount,
        }),
        (item, index) => (
          <ListItem
            {...ListItemProps(item.path)}
            sx={item.sx || {}}
            key={index}
          >
            <Box className={classes.navItemIcon}>{item.icon}</Box>
            {!isTablet && (
              <>
                <Typography variant="body2" component="span" fontWeight={900}>
                  {item.label}
                </Typography>
                {typeof item.counter !== "undefined" && (
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.navLabel}
                  >
                    {item.counter}
                  </Typography>
                )}
              </>
            )}
          </ListItem>
        )
      )}
      {!isLoading && (
        <ListItem
          className={classes.userNavItem}
          component={NavLink}
          to={ROUTE_PROFILE}
        >
          <Box className={classes.navItemIcon}>
            <Avatar alt={data.fullName || "N/A"} className={classes.avatar}>
              {data.initials || "N/A"}
            </Avatar>
          </Box>
          {!isTablet && (
            <Box>
              <Typography
                variant="subtitle2"
                component="div"
                overflow="hidden"
                textOverflow="ellipsis"
                height={22}
              >
                {data.fullName || "N/A"}
              </Typography>
              <Typography variant="caption" component="div">
                {data.role}
              </Typography>
            </Box>
          )}
        </ListItem>
      )}
    </List>
  );
};
