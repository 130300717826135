import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { PageHeader } from "../../components/page-header";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { LabeledList } from "../../components/labeled-list";
import { ROUTE_PROFILE_EDIT, ROUTE_SIGN_IN } from "../../core";
import { useUser } from "../../core/hooks/useUser";
import { Loading } from "../../components/loading";

export const ProfileContainer = () => {
  const [openConfirm, closeConfirmDialog] = useDialog(ConfirmDialog);
  const navigate = useNavigate();
  const { data, isLoading } = useUser();

  const handleLogout = () => {
    openConfirm({
      title: "Are you really want to logout?",
      message: " ",
      onConfirm: () => {
        localStorage.removeItem("accessToken");
        closeConfirmDialog();
        navigate(ROUTE_SIGN_IN);
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title="Profile">
        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to={ROUTE_PROFILE_EDIT}
        >
          Edit
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </PageHeader>

      <LabeledList
        items={[
          {
            label: "Name",
            value: data.fullName || "-",
          },
          {
            label: "Email",
            value: data.email || "-",
          },
          {
            label: "Created",
            value: data.createdAtFormatted || "-",
          },
          {
            label: "Updated",
            value: data.updatedAtFormatted || "-",
          },
          {
            label: "Last login",
            value: data.lastLogin || "-",
          },
        ]}
      />
    </>
  );
};
