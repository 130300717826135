import { ComponentType } from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useResponsive } from "../../core/hooks/useResponsive";

interface Props {
  items: { label: string; url?: string }[];
}
export const Breadcrumbs: ComponentType<Props> = ({ items }) => {
  const { mobileValue } = useResponsive();
  const spacing = mobileValue(1, 2);

  return (
    <Stack spacing={spacing} direction="row">
      {items.map((item, index) => (
        <Stack key={index} spacing={spacing} direction="row">
          {item.url ? (
            <Typography
              variant="body2"
              component={Link}
              to={item.url}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              {item.label}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.disabled" component="span">
              {item.label}
            </Typography>
          )}
          {index !== items.length - 1 && (
            <Typography variant="body2" color="text.disabled" component="span">
              •
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
