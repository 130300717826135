import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import {
  signInApi,
  locationApi,
  placesApi,
  usersApi,
  profileApi,
  dashboardApi,
  counterApi,
} from "../store/api";
import { rtkQueryErrorLogger } from "./rtk.utils";

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        rtkQueryErrorLogger,
        signInApi.middleware,
        locationApi.middleware,
        placesApi.middleware,
        usersApi.middleware,
        profileApi.middleware,
        dashboardApi.middleware,
        counterApi.middleware,
      ]),
  });
};

export const store = makeStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
// export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
