import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { useCreateOnePartyMutation } from "../store/api/party.api";

interface UseProps {
  onSuccess: () => void;
}

export const usePartyForm = ({ onSuccess }: UseProps) => {
  const [create, { isLoading }] = useCreateOnePartyMutation();

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      hosts: [],
      invitations: [],
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Required field"),
      hosts: yup.array().min(1, "Required field").required("Required field"),
      startDate: yup
        .date()
        .max(new Date(), "Start date must be in the future")
        .required(),
      endDate: yup.string().required(),
    }),
    onSubmit: async (values) => {
      try {
        // await create(values).unwrap();
        onSuccess();
      } catch (e) {
        console.log(e);
      }
    },
  });

  console.log("errors", errors);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    isLoading,
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
