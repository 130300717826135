import { Shadows } from "@mui/material/styles/shadows";

export const shadows: Shadows = [
  "none",
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 0 Card Shadow
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 1
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 2
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 3
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 4
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 5
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 6
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 7
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 8
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 9
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 10
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 11
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 12
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 13
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 14
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 15
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 16
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 17
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 18
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 19
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 2
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 21
  "0px 0px 65px rgba(14, 60, 71, 0.1)", // 23
  "0px 12px 40px #BBBBBB", // 24 PopUp Shadow
];
