import TextTruncate from "react-text-truncate";
import { Box } from "@mui/material";
import { format } from "date-fns";

export const partyColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    cellProps: {
      width: 100,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    mobileHeader: true,
    key: "name",
  },
  {
    title: "Host",
    dataIndex: "host",
    key: "host",
    render: ({ firstName, lastName }) =>
      [firstName, lastName].filter(Boolean).join(" "),
  },
  {
    title: "Date",
    dataIndex: "startTime",
    key: "startDate",
    render: (date) => format(new Date(date), "dd/MM/yyyy"),
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    render: (date) => format(new Date(date), "hh:mm a"),
  },
  {
    title: "End time",
    dataIndex: "endTime",
    key: "endTime",
    render: (date) => format(new Date(date), "hh:mm a"),
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    cellProps: {
      sx: {
        width: 240,
      },
    },
    render: (text) => (
      <Box width={208}>
        <TextTruncate line={1} truncateText="…" text={text} />
      </Box>
    ),
  },
];
