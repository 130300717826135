import { Autocomplete, TextField, Box, Chip } from "@mui/material";
import React, { FC, useMemo } from "react";
import { keyBy, map } from "lodash";
import get from "lodash/get";
import Stack from "@mui/material/Stack";

type Option = {
  label: string;
  value: string | number;
};

interface Props {
  label?: string;
  placeholder?: string;
  options: Option[];
  onChange: (e: any) => any;
  value: any;
  size?: "small" | "medium";
  width?: number;
}
export const FieldAutocompleteSelect: FC<Props> = ({
  label,
  placeholder = "Select",
  options,
  onChange,
  value,
  width,
  ...props
}) => {
  const optionsByKey = useMemo(() => keyBy(options, "value"), [options]);

  const handleChange = (e, value, value2) => {
    const newValues = map(value, (x) => (x?.value ? x.value : x));
    onChange && onChange(newValues);
  };

  const handleDelete = (deleteValue) => () => {
    onChange && onChange(value.filter((x) => x !== deleteValue));
  };

  return (
    <Box>
      <Autocomplete
        multiple
        sx={{ maxWidth: width }}
        placeholder="Select"
        {...{ options, value, ...props }}
        getOptionLabel={(x: Option) => {
          return x.label;
        }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => {
          return option.value === value;
        }}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ label }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              placeholder,
            }}
          />
        )}
      />
      <Stack direction="row" mt={1} gap={1.5} flexWrap="wrap">
        {map(value, (x) => {
          return (
            <Chip
              label={get(optionsByKey, [x, "label"])}
              size="small"
              onDelete={handleDelete(x)}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
