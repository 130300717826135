import React, { FC } from "react";
import { Toolbar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTE_DASHBOARD } from "../../../core";
import { IconClose, IconMenu } from "../../icons";
import IconButton from "@mui/material/IconButton";
import { Nav } from "../nav/Nav";
import useStyles from "./styles";
import clsx from "clsx";

interface SidebarProps {}

export const Header: FC<SidebarProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Box
      className={clsx(classes.root, {
        [classes.rootOpen]: open,
      })}
      component="header"
    >
      <Toolbar className={classes.toolbar}>
        <Link to={ROUTE_DASHBOARD} className={classes.toolbarLink}>
          <img
            className={classes.logoImg}
            src={"/assets/images/sidebar-logo.svg"}
            alt="WYA"
          />
        </Link>
        <IconButton onClick={handleToggle}>
          {open ? <IconClose /> : <IconMenu />}
        </IconButton>
      </Toolbar>
      <Nav />
    </Box>
  );
};
