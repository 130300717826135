import { Exclude, Expose, Transform } from "class-transformer";
import { formatToDate } from "../utils/date.utils";

@Exclude()
export class _TemplateDto {
  @Expose()
  id: string;

  @Expose()
  createdAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.createdAt))
  createdAtFormatted: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform(({ obj }) => formatToDate(obj.updatedAt))
  updatedAtFormatted: string;
}
