import { createApi } from "@reduxjs/toolkit/query/react";
import {
  baseQuery,
  FindOneProps,
  ListResponse,
  listResponse,
} from "../rtk.utils";
import { plainToInstance } from "class-transformer";
import {
  PARTY_CREATE,
  PARTY_FIND_ALL,
  PARTY_FIND_ONE,
  PARTY_UPDATE,
} from "../endpoint.constants";
import { url } from "../../utils";
import { PartyDto } from "../../dto";
import { FormikValues } from "formik";

const TAG = "PARTY";
const ID = "ID";

export const partyApi = createApi({
  reducerPath: "partyApi",
  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [TAG],
  endpoints: (builder) => ({
    /**
     * Find all
     */
    findAllParty: builder.query<ListResponse<PartyDto>, Record<string, any>>({
      query: (query) => ({
        url: PARTY_FIND_ALL,
        method: "GET",
        query,
      }),
      transformResponse: (response) =>
        listResponse<PartyDto>(PartyDto, response),
      providesTags: [{ type: TAG, id: ID }],
    }),

    /**
     * Find one
     */
    findOneParty: builder.query<PartyDto, FindOneProps<PartyDto["id"]>>({
      query: ({ id, params = {} }) => ({
        url: url(PARTY_FIND_ONE, { id }),
        params,
      }),
      transformResponse: (response) => plainToInstance(PartyDto, response),
      keepUnusedDataFor: 0,
    }),

    /**
     * Create one
     */
    createOneParty: builder.mutation<PartyDto, FormikValues>({
      query: (body) => ({
        url: PARTY_CREATE,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),

    /**
     * Update one
     */
    updateOneParty: builder.mutation<
      PartyDto,
      { id: PartyDto["id"]; body: FormikValues }
    >({
      query: ({ id, body }) => ({
        url: url(PARTY_UPDATE, { id }),
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),
  }),
});

export const {
  useFindAllPartyQuery,
  useFindOnePartyQuery,
  useCreateOnePartyMutation,
  useUpdateOnePartyMutation,
} = partyApi;
