import React, { FC, PropsWithChildren } from "react";
import { Box } from "@mui/material";
import useStyles from "./styles";

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box component="main" className={classes.root}>
        <Box className={classes.container}>{children}</Box>
      </Box>
    </>
  );
};
