import { Components, createTheme } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { typography } from "./typography";
import { IconExpandMore } from "../../components/icons";

export const themeComponents = (palette: PaletteOptions): Components => {
  const theme = createTheme();

  return {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
        SelectProps: {
          IconComponent: IconExpandMore,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#919EAB",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: theme.palette.common.black,
          "&:hover": {
            backgroundColor: theme.palette.common.black,
            opacity: 0.9,
          },
        },
        sizeLarge: {
          padding: theme.spacing(1.375, 2.75),
          fontSize: 15,
          lineHeight: "26px",
        },
        sizeSmall: {
          fontSize: 13,
          lineHeight: "22px",
        },
        outlinedSecondary: {
          border: "1px solid rgba(145, 158, 171, 0.32)",
          color: "#212B36",
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB",
          "& .MuiTableCell-root": {
            color: "#919EAB",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "none",
            padding: theme.spacing(0.625, 2),
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            ...typography.body2,
            lineHeight: "22px",
            padding: theme.spacing(1.625, 2),
            borderBottom: "1px solid #F4F6F8",
          },
          "& .MuiTableRow-root:hover": {
            backgroundColor: "#F9FBFE",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "common.dark",
          borderColor: "#ECF0F7",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          " &:hover": {
            cursor: "pointer",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 33,

          "& .MuiButtonBase-root": {
            fontWeight: 900,
            color: "#637381",
          },
        },
        indicator: {
          height: 2,
          backgroundColor: "#FA1F20",
          borderRadius: 2,
          padding: 0,
          zIndex: 10,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "22px",
          fontFamily: "Inter, sans-serif",
          color: "#637381",
          padding: theme.spacing(1.625, 0),
          marginRight: theme.spacing(5),
          minHeight: 33,
          minWidth: "auto",
          "&.Mui-selected": {
            color: "#212B36",
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            background: "rgba(22, 28, 36, 0.8)",
          },
        },
        paper: {
          borderRadius: 16,
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 900,
          padding: theme.spacing(3, 3, 1.5, 3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 3, 3),
          "& > :not(:first-of-type)": {
            marginLeft: theme.spacing(1.5),
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <IconExpandMore />,
      },
      styleOverrides: {
        root: {
          ".MuiFormLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            backgroundColor: "#fff",
            paddingLeft: 5,
            paddingRight: 5,
            width: "auto",
          },
          ".MuiButtonBase-root": {
            color: "red",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            "& .MuiSvgIcon-root": {
              color: "red",
            },
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: "inline-flex",
          padding: theme.spacing(0.5),
          borderRadius: 4,
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          margin: "0 4px 0 0",
          color: "#212B36",
          border: "1px solid rgba(145, 158, 171, 0.32)",
          "&.Mui-selected, &:hover, &.Mui-selected:hover": {
            background: "rgba(145, 158, 171, 0.16)",
          },
          "&.MuiPaginationItem-ellipsis": {
            background: "transparent",
            border: 0,
          },
        },
      },
    },
  };
};
