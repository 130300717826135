import { Routes as RouterRoutes, Route } from "react-router-dom";
import {
  DashboardIndex,
  EmptyPage,
  PlacesCreate,
  PlacesDetail,
  PlacesEdit,
  PlacesIndex,
  ProfileEdit,
  ProfileIndex,
  UsersDetail,
  UsersIndex,
  SignInPage,
  PartiesIndex,
  PartiesDetail,
  PartiesCreate,
  PartiesEdit,
  NotificationsIndex,
  NotificationsEdit,
  NotificationsCreate,
} from "./pages";
import {
  ROUTE_DASHBOARD,
  ROUTE_HOME,
  ROUTE_NOTIFICATIONS,
  ROUTE_NOTIFICATIONS_CREATE,
  ROUTE_NOTIFICATIONS_EDIT,
  ROUTE_PARTIES,
  ROUTE_PARTIES_CREATE,
  ROUTE_PARTIES_DETAIL,
  ROUTE_PARTIES_EDIT,
  ROUTE_PLACES,
  ROUTE_PLACES_CREATE,
  ROUTE_PLACES_DETAIL,
  ROUTE_PLACES_EDIT,
  ROUTE_PROFILE,
  ROUTE_PROFILE_EDIT,
  ROUTE_PROMO_CODES,
  ROUTE_SIGN_IN,
  ROUTE_USERS,
  ROUTE_USERS_DETAIL,
} from "./core";

import { ProtectedRoute } from "./components/protected-route";

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path={ROUTE_HOME} element={<SignInPage />} />
      <Route path={ROUTE_SIGN_IN} element={<SignInPage />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path={ROUTE_DASHBOARD} element={<DashboardIndex />} />

        <Route path={ROUTE_PLACES} element={<PlacesIndex />} />
        <Route path={ROUTE_PLACES_DETAIL} element={<PlacesDetail />} />
        <Route path={ROUTE_PLACES_CREATE} element={<PlacesCreate />} />
        <Route path={ROUTE_PLACES_EDIT} element={<PlacesEdit />} />

        <Route path={ROUTE_USERS} element={<UsersIndex />} />
        <Route path={ROUTE_USERS_DETAIL} element={<UsersDetail />} />

        <Route path={ROUTE_PARTIES} element={<PartiesIndex />} />
        <Route path={ROUTE_PARTIES_DETAIL} element={<PartiesDetail />} />
        <Route path={ROUTE_PARTIES_CREATE} element={<PartiesCreate />} />
        <Route path={ROUTE_PARTIES_EDIT} element={<PartiesEdit />} />

        <Route
          path={ROUTE_PROMO_CODES}
          element={<EmptyPage title="Promo codes" />}
        />

        <Route path={ROUTE_NOTIFICATIONS} element={<NotificationsIndex />} />
        <Route
          path={ROUTE_NOTIFICATIONS_CREATE}
          element={<NotificationsCreate />}
        />
        <Route
          path={ROUTE_NOTIFICATIONS_EDIT}
          element={<NotificationsEdit />}
        />

        <Route path={ROUTE_PROFILE} element={<ProfileIndex />} />
        <Route path={ROUTE_PROFILE_EDIT} element={<ProfileEdit />} />
      </Route>
    </RouterRoutes>
  );
};

function App() {
  return <Routes />;
}

export default App;
