export const AUTH_REQUEST_CODE = "/api/v1/sms/request-code";
export const AUTH_VERIFY_CODE = "/api/v1/sms/verify-code";

export const GET_USERS = "/api/v1/admin/users";
export const GET_USER_BY_ID = "/api/v1/admin/users/:id";
export const BAN_USER = "/api/v1/admin/users/ban";
export const UNBAN_USER = "/api/v1/admin/users/unban";
export const DELETE_USER = "/api/v1/admin/users/:userId";

export const GET_PLACES = "/api/v1/admin/places";
export const GET_PLACE_BY_ID = "/api/v1/admin/places/:id";

export const GET_ADMIN_LOCATIONS = "/api/v1/admin/locations";

export const PROFILE_ME = "/api/v1/profile";
export const PROFILE_UPDATE = "/api/v1/profile";

export const GET_DASHBOARD = "/api/v1/admin/statistics";

export const MARK_PLACE_AS_FEATURED =
  "/api/v1/admin/place/:placeId/make-featured";
export const MARK_PLACE_AS_UNFEATURED =
  "/api/v1/admin/place/:placeId/make-unfeatured";
export const DELETE_PLACE = "/api/v1/admin/place/:placeId";
export const CREATE_PLACE = "/api/v1/admin/place";
export const EDIT_PLACE = "/api/v1/admin/places/:placeId";
export const GET_PLACE_TOKEN_BY_ID = "/api/v1/admin/places/:id/token";
export const UPDATE_PLACE_TOKEN_BY_ID = "/api/v1/admin/places/:id/token";
export const GET_PLACE_STATISTICS_BY_ID = "/api/v1/admin/places/:id/statistics";

export const GET_PLACE_CATEGORIES = "/api/v1/place-categories";
export const GET_COUNTERS = "/api/v1/admin/counter";
export const GET_ADDRESSES = "/api/v1/admin/address";
