import * as yup from "yup";
import { useFormik } from "formik";

import { useRequestCodeMutation } from "../store/api";
import { get } from "lodash";

interface useRequestCodeProps {
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useRequestCode = ({ onSuccess, onError }: useRequestCodeProps) => {
  const [requestCode, { isLoading }] = useRequestCodeMutation();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup
        .string()
        .min(10, "Phone number must be at least 10 characters")
        .max(12, "Phone number must be at most 12 characters")
        .required("Required field"),
    }),
    onSubmit: async (values) => {
      const formattedPhoneNumber = `+${values.phoneNumber}`;
      const res = await requestCode({ phoneNumber: formattedPhoneNumber });
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.message", "Something went wrong");
        onError(message);
      } else {
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
    };
  };

  return {
    handleSubmit,
    values,
    getFieldProps,
    setFieldValue,
    isLoading,
  };
};
