import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";
import { GET_DASHBOARD } from "../../http/api.routes";

interface IGetDashboardResponse {
  monthUserCount: number;
  newUserCount: number;
  todayUserCount: number;
  totalCommitmentCount: number;
  totalPlaceCount: number;
  totalUserCount: number;
  weekUserCount: number;
}

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getDashboard: builder.query<IGetDashboardResponse, any>({
      query: (params) => ({
        url: GET_DASHBOARD,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
