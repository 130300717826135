import React, { FC, useState } from "react";
import { format, subMonths } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import { Popover, Stack, TextField, InputAdornment } from "@mui/material";
import { DISPLAY_ONLY_DATE_FORMAT } from "../../../core";
import { IconArrowDown } from "../../icons";
import "./style.css";
//https://react-day-picker.js.org/basics/selecting-days

const defaultMonth = subMonths(new Date(), 1);

interface Props {
  width?: number | string;
  range: DateRange | undefined;
  setRange?: (range: DateRange) => void;
}

export const FieldDateRange: FC<Props> = ({ width = 150, range, setRange }) => {
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack alignItems="flex-start" direction="row" spacing={2} ref={ref}>
        <TextField
          size="medium"
          sx={{ width, cursor: "pointer" }}
          label="Date from"
          placeholder="DD.MM.YYYY"
          value={
            range?.from ? format(range.from, DISPLAY_ONLY_DATE_FORMAT) : ""
          }
          onClick={() => {
            setOpen(true);
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconArrowDown />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width, cursor: "pointer" }}
          label="Date to"
          placeholder="DD.MM.YYYY"
          value={range?.to ? format(range.to, DISPLAY_ONLY_DATE_FORMAT) : ""}
          onClick={() => {
            setOpen(true);
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconArrowDown />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DayPicker
          mode="range"
          numberOfMonths={2}
          defaultMonth={defaultMonth}
          selected={range}
          onSelect={setRange}
        />
      </Popover>
    </>
  );
};
