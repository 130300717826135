import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "./core/theme/theme-provider";
import reportWebVitals from "./reportWebVitals";
import { store } from "./core/store";
import App from "./App";
import { SnackbarProvider } from "./core/providers/snackbar";
import { DialogProvider } from "./core/providers/dialog.provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StoreProvider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <SnackbarProvider>
          <DialogProvider>
            <App />
          </DialogProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
