import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AUTH_TOKEN } from "../../core/constants/app.constants";
import { ROUTE_SIGN_IN } from "../../core/constants/route.constants";
import { url } from "../../core/utils/route.utils";

export const ProtectedRoute = () => {
  const auth = !!localStorage.getItem(AUTH_TOKEN);

  return auth ? <Outlet /> : <Navigate to={url(ROUTE_SIGN_IN)} />;
};
