import React, { ComponentType, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Grid } from "@mui/material";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { StatsCard } from "../../../components/stats-card";
import { formatNumber } from "../../../core";
import { TableView } from "../../../components/table-view";
import { usersData } from "../../../core/_mockdata";
import { attendedUserColumns } from "../../../core";
import { useResponsive } from "../../../core/hooks/useResponsive";
import { LabeledRecord } from "../../../components/labeled-record";

interface Props extends DialogProps {
  id: string;
  total?: number;
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  body?: ReactNode;
}

export const AttendedDialog: ComponentType<Props> = ({ popDialog }) => {
  const { tabletAndMobileValue, mobileValue, isMobile } = useResponsive();
  const minWidth = tabletAndMobileValue("90%", 880);
  const handlePageChange = () => {};

  const pagination = {
    page: 1,
    perPage: 14,
    totalPages: 10,
    total: 140,
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { minWidth } }}
    >
      <DialogTitle>Attended WAYs</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={5} mt={1.5}>
          <Grid item md={4} xs={12}>
            <StatsCard title="Total" value={formatNumber(324)} />
          </Grid>
          <Grid item md={4} xs={12}>
            <StatsCard title="Women" value={formatNumber(234)} />
          </Grid>
          <Grid item md={4} xs={12}>
            <StatsCard title="Men" value={formatNumber(93)} />
          </Grid>
        </Grid>

        <TableView
          hideColumns={mobileValue(["id", "gender", "phoneNumber"], [])}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <LabeledRecord label="#" value={record.id} />
                <LabeledRecord label="Gender" value={record.gender} />
                <LabeledRecord
                  label="Phone number"
                  value={record.phoneNumber}
                />
              </>
            ),
            rowExpandable: () => isMobile,
          }}
          pagination={pagination}
          onChangePage={handlePageChange}
          dataSource={usersData.slice(0, 10)}
          columns={attendedUserColumns}
          showEmptyState
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
