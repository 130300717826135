import React from "react";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate } from "react-router-dom";
import { ROUTE_PARTIES, ROUTE_USERS } from "../../core";
import { FieldSelect } from "../../components/fields";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { map } from "lodash";
import { usersData } from "../../core/_mockdata";
import { FieldUserSelect } from "../../components/fields/field-user-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { IconExpandMore } from "../../components/icons";
import { usePartyForm } from "../../core/hooks/usePartyForm";

export const PartyFormContainer = () => {
  const navigate = useNavigate();
  const { handleSubmit, getFieldProps, isLoading } = usePartyForm({
    onSuccess: () => {
      console.log("onSuccess");
    },
  });

  return (
    <>
      <DetailHeader
        title={"Add new party"}
        header={
          <Breadcrumbs
            items={[
              { label: "Parties", url: ROUTE_PARTIES },
              {
                label: "Add new party",
              },
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_USERS)}
        >
          Cancel
        </Button>
      </DetailHeader>

      <Box mb={3}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <TextField label="Name" {...getFieldProps("name")} size="small" />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <FieldUserSelect
              label="Hosts"
              size="small"
              width={555}
              options={map(usersData, (i, index) => ({
                label: [i.firstName, i.lastName].filter(Boolean).join(" "),
                avatar: `https://i.pravatar.cc/150?img=${index}`,
                value: i.id,
              }))}
              {...getFieldProps("hosts")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={2}>
            <DatePicker
              label="Date"
              components={{
                OpenPickerIcon: IconExpandMore,
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              {...getFieldProps("startDate")}
            />
          </Grid>
          <Grid item xs={2}>
            <TimePicker
              label="Start time"
              components={{
                OpenPickerIcon: IconExpandMore,
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              {...getFieldProps("startDate")}
            />
          </Grid>
          <Grid item xs={2}>
            <TimePicker
              label="End time"
              components={{
                OpenPickerIcon: IconExpandMore,
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              {...getFieldProps("endDate")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <FieldSelect
              label="Location"
              items={[{ label: "New York, NY", value: "ny" }]}
              size="small"
              {...getFieldProps("location")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <TextField
              label="Address line 1"
              size="small"
              {...getFieldProps("addressLine1")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Address line 2"
              size="small"
              {...getFieldProps("addressLine2")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <FieldSelect
              label="State"
              items={[{ label: "New York, NY", value: "ny" }]}
              size="small"
              {...getFieldProps("state")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField label="City" {...getFieldProps("city")} size="small" />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Zip code"
              size="small"
              {...getFieldProps("zip")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <TextField
              label="Description"
              size="small"
              multiline
              rows={2}
              {...getFieldProps("description")}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mx: -3, mb: 3 }} />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <FieldUserSelect
            label="Invitations"
            size="small"
            width={555}
            options={map(usersData, (i, index) => ({
              label: [i.firstName, i.lastName].filter(Boolean).join(" "),
              avatar: `https://i.pravatar.cc/150?img=${index}`,
              value: i.id,
            }))}
            {...getFieldProps("invitations")}
          />
        </Grid>
      </Grid>
    </>
  );
};
