import React from "react";
import { PageHeader } from "../../components/page-header";
import { Button } from "@mui/material";
import { TableView } from "../../components/table-view";
import { Link } from "react-router-dom";
import { notificationColumns, ROUTE_NOTIFICATIONS_CREATE } from "../../core";
import { notificationData } from "../../core/_mockdata";
import { useResponsive } from "../../core/hooks/useResponsive";
import { LabeledRecord } from "../../components/labeled-record";
import { formatToDate } from "../../core/utils/date.utils";

export const NotificationListContainer = () => {
  const { mobileValue, isMobile } = useResponsive();

  const handlePageChange = () => {};

  const pagination = {
    page: 1,
    perPage: 14,
    totalPages: 10,
    total: 140,
  };

  return (
    <>
      <PageHeader title="WYA notifications" divider>
        <Button
          variant="contained"
          component={Link}
          to={ROUTE_NOTIFICATIONS_CREATE}
        >
          Add new
        </Button>
      </PageHeader>
      <TableView
        hideColumns={mobileValue(
          ["id", "location", "recipientsCount", "isSent", "createdAt"],
          []
        )}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <LabeledRecord label="#" value={record.id} />
              <LabeledRecord label="Location" value={record.location} />
              <LabeledRecord
                label="Recipients"
                value={record.recipientsCount}
              />
              <LabeledRecord
                label="IsSent"
                value={record.isSent ? "Yes" : "No"}
              />
              <LabeledRecord
                label="Creation date"
                value={formatToDate(record.createdAt)}
              />
            </>
          ),
          rowExpandable: () => isMobile,
        }}
        pagination={pagination}
        onChangePage={handlePageChange}
        dataSource={notificationData}
        columns={notificationColumns}
        showEmptyState
      />
    </>
  );
};
