import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "adaptive-image",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      "& img": {
        maxWidth: "100%",
        width: "400px",
        height: "auto",
      },
    },
  }),
  options
);

export default useStyles;
