import React, { useEffect, useMemo } from "react";
import { get, isEmpty, map } from "lodash";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { TableView } from "../../components/table-view";
import {
  placeColumns,
  ROUTE_PLACES_CREATE,
  ROUTE_PLACES_DETAIL,
  url,
} from "../../core";
import { PageHeader } from "../../components/page-header";
import { FieldSearch, FieldSelect } from "../../components/fields";
import { LabeledRecord } from "../../components/labeled-record";
import { useResponsive } from "../../core/hooks/useResponsive";
import { useGetAdminLocationsQuery } from "../../core/store/api";
import { useGetPlacesQuery } from "../../core/store/api";
import useDebounce from "../../core/hooks/useDebounce";
import { Loading } from "../../components/loading";

export const PlaceListContainer = () => {
  const { mobileValue, isMobile } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const debouncedValue = useDebounce(searchParams.get("search"), 500);

  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );
  const pageValue = useMemo(() => searchParams.get("page"), [searchParams]);
  const { data } = useGetAdminLocationsQuery({});
  const adminLocationsList = map(get(data, "items", []), (item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const { data: placesData, isFetching } = useGetPlacesQuery(
    {
      locationId: locationValue,
      search: debouncedValue,
      page: pageValue || 1,
    },
    {
      skip: !locationValue,
    }
  );
  const placesList = map(get(placesData, "items", []), (item) => ({
    ...item,
    id: item.id.uuid,
    name: item.name,
    category: item.categories[0],
    address: item.address.address,
    phoneNumber: item.phoneNumber,
    commitments: item.commitments,
    isFeatured: item.isFeatured,
  }));

  const handleFilter = (key, value) => {
    if (key) {
      if (value) {
        searchParams.set(key, value);
        searchParams.set("page", "1");
      } else {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
  };

  const handlePageChange = (value) => {
    if (value) {
      searchParams.set("page", value);
    } else {
      searchParams.delete("page");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!locationValue && !isEmpty(adminLocationsList)) {
      handleFilter("location", adminLocationsList[0]?.value);
    }
  }, [locationValue, adminLocationsList]);

  useEffect(() => {
    if (!pageValue) {
      handleFilter("page", placesData?.currentPage || 1);
    }
  }, []);

  const pagination = {
    page: placesData?.currentPage || 1,
    perPage: placesData?.recordsPerPage || 10,
    totalPages: placesData?.totalPages || 1,
    total: placesData?.totalPages || 1,
  };
  return (
    <>
      <PageHeader title="Places" divider>
        <Button
          variant="contained"
          component={Link}
          to={`${ROUTE_PLACES_CREATE}?location=${locationValue}`}
        >
          Add new
        </Button>
      </PageHeader>
      <Grid container spacing={2} mb={1.5}>
        <Grid item md={4} xs={12}>
          <FieldSelect
            label="Location"
            size="small"
            value={locationValue}
            placeholder="Select location"
            items={adminLocationsList}
            onChange={(value) => handleFilter("location", value)}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <FieldSearch
            onChange={(value) => handleFilter("search", value)}
            size="small"
          />
        </Grid>
      </Grid>

      <Box mb={4.5}>
        <Typography variant="caption">
          {" "}
          Results: {placesData?.totalRecords || 0}
        </Typography>
      </Box>
      {isFetching ? (
        <Loading />
      ) : (
        <TableView
          hideColumns={mobileValue(
            [
              "id",
              "category",
              "address",
              "phoneNumber",
              "commitments",
              "isFeatured",
            ],
            []
          )}
          expandable={
            isMobile && {
              expandedRowRender: (record) => (
                <>
                  <LabeledRecord label="#" value={record.id} />
                  <LabeledRecord label="Category" value={record.category} />
                  <LabeledRecord label="Address" value={record.address} />
                  <LabeledRecord
                    label="Phone number"
                    value={record.phoneNumber}
                  />
                  <LabeledRecord
                    label="Commitments"
                    value={record.commitments}
                  />
                  <LabeledRecord
                    label="IsFeatured"
                    value={record.isFeatured ? "Yes" : "No"}
                  />
                </>
              ),
              rowExpandable: () => isMobile,
            }
          }
          pagination={pagination}
          onChangePage={(value) => handlePageChange(value)}
          onRowClick={({ id }) =>
            navigate(
              url(`${ROUTE_PLACES_DETAIL}?location=${locationValue}`, { id })
            )
          }
          dataSource={placesList}
          columns={placeColumns}
          showEmptyState
        />
      )}
    </>
  );
};
