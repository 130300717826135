import { Button } from "@mui/material";
import { Tabs } from "../../components/tabs";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTE_PLACES, ROUTE_PLACES_EDIT, url } from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { get } from "lodash";
import { Tokens } from "./tabs/Tokens";
import {
  useGetPlaceByIdQuery,
  useGetPlaceStatisticsByIdQuery,
  useGetPlaceTokenByIdQuery,
  useMarkPlaceAsFeaturedMutation,
  useMarkPlaceAsUnfeaturedMutation,
} from "../../core/store/api";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useMemo } from "react";

export const PlaceDetailContainer = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );
  const navigate = useNavigate();
  const [openConfirm, popConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const { data, refetch } = useGetPlaceByIdQuery({ id });
  const { data: tokenData } = useGetPlaceTokenByIdQuery({
    id,
  });
  const { data: statisticsData } = useGetPlaceStatisticsByIdQuery({ id });
  const [markPlaceAsFeatured, { isLoading: isMarkPlaceAsFeaturedLoading }] =
    useMarkPlaceAsFeaturedMutation({});
  const [markPlaceAsUnfeatured, { isLoading: isMarkPlaceAsUnfeaturedLoading }] =
    useMarkPlaceAsUnfeaturedMutation({});

  const handleMarkAsFeatured = () => {
    openConfirm({
      title: "Mark this place as featured",
      message: "Are you sure you want to mark this place as featured?",
      onConfirm: async () => {
        const res = await markPlaceAsFeatured({ placeId: id });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          popConfirm();
          showMessage(
            "Place has been marked featured successfully.",
            "success"
          );
          refetch();
        }
      },
    });
  };

  const handleMarkAsUnfeatured = () => {
    openConfirm({
      title: "Mark this place as unfeatured",
      message: "Are you sure you want to mark this place as unfeatured?",
      onConfirm: async () => {
        const res = await markPlaceAsUnfeatured({ placeId: id });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          popConfirm();
          showMessage(
            "Place has been marked unfeatured successfully.",
            "success"
          );
          refetch();
        }
      },
    });
  };

  const handleEdit = () => {
    navigate(
      url(`${ROUTE_PLACES_EDIT}?location=${locationValue}`, {
        id,
      })
    );
  };

  return (
    <>
      <DetailHeader
        title={data?.name}
        divider={false}
        header={
          <Breadcrumbs
            items={[
              {
                label: "Places",
                url: `${ROUTE_PLACES}?location=${locationValue}`,
              },
              { label: data?.name },
            ]}
          />
        }
      >
        <Button color="secondary" variant="outlined" onClick={handleEdit}>
          Edit
        </Button>
        {data?.isFeatured ? (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleMarkAsUnfeatured}
            disabled={isMarkPlaceAsUnfeaturedLoading}
          >
            Mark as unfeatured
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleMarkAsFeatured}
            disabled={isMarkPlaceAsFeaturedLoading}
          >
            Mark as featured
          </Button>
        )}
      </DetailHeader>

      <Tabs
        hashed
        items={[
          {
            label: "Details",
            key: "details",
            content: (
              <Detail payload={data} statisticsPayload={statisticsData} />
            ),
          },
          {
            label: "Token",
            key: "tokens",
            content: <Tokens />,
          },
        ]}
      />
    </>
  );
};
