import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DISPLAY_DATE_FORMAT, ROUTE_PLACES, WEEK_DAYS } from "../../core";
import { FieldPhone } from "../../components/fields";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { format } from "date-fns";
import { cloneDeep, get, isEmpty, map } from "lodash";
import { useFormik } from "formik";
import { FieldAutocompleteSelect } from "../../components/fields";
import { FieldFromToTime } from "../../components/fields/field-from-to-time/FieldFromToTime";
import { useResponsive } from "../../core/hooks/useResponsive";
import {
  useCreatePlaceMutation,
  useEditPlaceMutation,
  useGetAddressesQuery,
  useGetPlaceByIdQuery,
  useGetPlaceCategoriesQuery,
} from "../../core/store/api";
import { Loading } from "../../components/loading";
import { transformTime } from "../../core/utils/date.utils";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { FieldAutocomplete } from "../../components/fields/field-autocomplete";

export const PlaceFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { mobileValue } = useResponsive();
  const [editPlace] = useEditPlaceMutation({});
  const [createPlace] = useCreatePlaceMutation({});
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useGetPlaceByIdQuery({ id }, { skip: !id });

  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );

  const { data: addressesData } = useGetAddressesQuery(
    { locationId: locationValue },
    { skip: !!id }
  );

  const addressesList = map(get(addressesData, "items", []), (item) => ({
    ...item,
    value: item.id.uuid,
    label: item.address,
  }));

  const { showMessage } = useSnackbar();

  const isEdit = !!id;

  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useGetPlaceCategoriesQuery({});

  const categoriesList = map(
    get(categoriesData, "values", []),
    (item: string) => ({
      value: item,
      label: item,
    })
  );

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: null,
      createdAt: "",
      updatedAt: "",
      categories: [],
      workingHours: [],
    },
    onSubmit: (values) => {
      if (isEdit) {
        if (
          values.workingHours.some((item) => !item.startTime || !item.endTime)
        ) {
          return showMessage("Please fill all working hours.", "error");
        }
        const formattedValues = {
          id,
          ...values,
          workingHours: values.workingHours.filter((item) => !item.dayOff),
        };
        editPlace(formattedValues);
        showMessage("Place has been saved.");
        navigate(`${ROUTE_PLACES}?location=${locationValue}`);
      } else {
        if (
          values.workingHours.some((item) => !item.startTime || !item.endTime)
        ) {
          return showMessage("Please fill all working hours.", "error");
        }
        const formattedValues = {
          id,
          ...values,
          addressId: values.address?.id?.uuid,
          workingHours: values.workingHours.filter((item) => !item.dayOff),
        };
        createPlace(formattedValues);
        showMessage("Place has been saved.");
        navigate(`${ROUTE_PLACES}?location=${locationValue}`);
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(categoriesData)) {
      setFieldValue("name", data.name);
      setFieldValue("phone", data.phone);
      setFieldValue("categories", data.categories);
      setFieldValue("workingHours", cloneDeep(data.workingHours));
    }
  }, [data, categoriesData, setFieldValue]);

  if (isLoading || isCategoriesLoading) {
    return <Loading />;
  }

  return (
    <>
      <DetailHeader
        title={isEdit ? data?.name : "Add new place"}
        header={
          <Breadcrumbs
            items={[
              { label: "Places", url: ROUTE_PLACES },
              {
                label: isEdit ? data?.name : "Add new place",
              },
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(`${ROUTE_PLACES}?location=${locationValue}`)}
        >
          Cancel
        </Button>
      </DetailHeader>
      {isEdit && (
        <Stack
          direction={mobileValue("column", "row")}
          spacing={mobileValue(1, 3)}
          mb={3}
        >
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Created:
            </Typography>
            <Typography variant="body2">
              {data.createdAt
                ? format(new Date(data.createdAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Updated:
            </Typography>
            <Typography variant="body2">
              {data.updatedAt
                ? format(new Date(data.updatedAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack spacing={2.5} mb={3}>
        <TextField label="Name" {...getFieldProps("name")} size="small" />

        <FieldAutocompleteSelect
          label="Category"
          size="small"
          width={360}
          options={categoriesList}
          value={values.categories}
          onChange={(value) => {
            setFieldValue("categories", value);
          }}
        />

        {!isEdit && (
          <FieldAutocomplete
            label="Address"
            size="small"
            placeholder="Address"
            dataSource={addressesList}
            value={values.address}
            onChange={(value) => {
              setFieldValue("address", value);
            }}
          />
        )}

        <FieldPhone
          label="Phone"
          {...getFieldProps("phone")}
          onChange={(value) => {
            setFieldValue("phone", value);
          }}
          size="small"
          sx={{
            maxWidth: 360,
          }}
        />
      </Stack>

      <Divider sx={{ mx: -3, mb: 3 }} />

      <Typography variant="subtitle1" mb={3}>
        Working hours
      </Typography>

      <Box>
        {map(WEEK_DAYS, (day, index) => (
          <Stack
            direction={mobileValue("column", "row")}
            mb={2.5}
            spacing={mobileValue(0.5, 3)}
            key={day}
            alignItems={mobileValue("flex-start", "center")}
          >
            <Box width={120}>
              <Typography variant="subtitle2" fontWeight={900}>
                {day}
              </Typography>
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      values.workingHours.findIndex(
                        (item) => item.dayIndex === index
                      ) === -1
                    }
                    onChange={(event) => {
                      const workingHours = [...values.workingHours];
                      const currentDayIndex = workingHours.findIndex(
                        (item) => item.dayIndex === index
                      );

                      if (currentDayIndex > -1) {
                        workingHours.splice(currentDayIndex, 1);
                      } else {
                        workingHours.push({
                          id: uuidv4(),
                          dayIndex: index,
                          isOvernight: false,
                        });
                      }
                      setFieldValue("workingHours", workingHours);
                    }}
                  />
                }
                label={<Typography variant="body2">Day off</Typography>}
              />
            </Box>
            <FieldFromToTime
              disabled={
                !values.workingHours.find((item) => item.dayIndex === index)
              }
              timeFrom={
                values.workingHours.find((item) => item.dayIndex === index)
                  ?.startTime
              }
              timeTo={
                values.workingHours.find((item) => item.dayIndex === index)
                  ?.endTime
              }
              setFromChange={(value) => {
                const workingHours = [...values.workingHours];
                const currentDayIndex = workingHours.findIndex(
                  (item) => item.dayIndex === index
                );

                if (currentDayIndex > -1) {
                  workingHours[currentDayIndex].startTime =
                    transformTime(value);
                } else {
                  workingHours.push({
                    id: uuidv4(),
                    dayIndex: index,
                    startTime: transformTime(value),
                    isOvernight: false,
                  });
                }
                setFieldValue("workingHours", workingHours);
              }}
              setToChange={(value) => {
                const workingHours = [...values.workingHours];
                const currentDayIndex = workingHours.findIndex(
                  (item) => item.dayIndex === index
                );

                if (currentDayIndex > -1) {
                  workingHours[currentDayIndex].endTime = transformTime(value);
                } else {
                  workingHours.push({
                    id: uuidv4(),
                    dayIndex: index,
                    endTime: transformTime(value),
                    isOvernight: false,
                  });
                }
                setFieldValue("workingHours", workingHours);
              }}
            />
          </Stack>
        ))}
      </Box>
    </>
  );
};
