import { Button } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { get } from "lodash";

import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { ROUTE_USERS } from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Breadcrumbs } from "../../components/breadcrumbs";
import {
  useGetUserByIdQuery,
  useBanUserMutation,
  useUnbanUserMutation,
} from "../../core/store/api";
import { StatusBadge } from "../../components/badges";
import { Loading } from "../../components/loading";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useMemo } from "react";

export const UserDetailContainer = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const locationValue = useMemo(
    () => searchParams.get("location"),
    [searchParams]
  );
  const { data, isFetching, refetch } = useGetUserByIdQuery({ id });
  const { showMessage } = useSnackbar();
  const [banUser, { isLoading: isBanUserLoading }] = useBanUserMutation({});
  const [unbanUser, { isLoading: isUnbanUserLoading }] = useUnbanUserMutation(
    {}
  );
  const [openConfirm, popConfirm] = useDialog(ConfirmDialog);

  const handleBan = () => {
    openConfirm({
      title: "Ban user",
      message: "Are really want to ban this user?",
      onConfirm: async () => {
        const res = await banUser({ userId: id });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          popConfirm();
          showMessage("User has been banned successfully.", "success");
          refetch();
        }
      },
    });
  };

  const handleUnban = () => {
    openConfirm({
      title: "Unban user",
      message: "Are really want to unban this user?",
      onConfirm: async () => {
        const res = await unbanUser({ userId: id });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.message", "Something went wrong.");
          showMessage(message, "error");
        } else {
          popConfirm();
          showMessage("User has been unbanned successfully.", "success");
          refetch();
        }
      },
    });
  };

  const name = [data?.firstName, data?.lastName].filter(Boolean).join(" ");

  return (
    <>
      <DetailHeader
        title={name}
        header={
          <Breadcrumbs
            items={[
              {
                label: "Users",
                url: `${ROUTE_USERS}?location=${locationValue}`,
              },
              {
                label: isFetching ? "" : name,
              },
            ]}
          />
        }
        meta={
          isFetching ? null : (
            <>
              {data?.isDeleted ? (
                <StatusBadge label="Deleted" variant="error" />
              ) : data?.isBanned ? (
                <StatusBadge label="Banned" variant="default" />
              ) : (
                <StatusBadge label="Active" variant="success" />
              )}
            </>
          )
        }
      >
        {data?.isBanned ? (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleUnban}
            disabled={isUnbanUserLoading}
          >
            Unban
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleBan}
            disabled={isBanUserLoading}
          >
            Ban
          </Button>
        )}
      </DetailHeader>
      {isFetching ? <Loading /> : <Detail payload={data} />}
    </>
  );
};
