import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as SvgDashboard } from "./assets/dashboard.svg";
import { ReactComponent as SvgPlaces } from "./assets/places.svg";
import { ReactComponent as SvgUsers } from "./assets/users.svg";
import { ReactComponent as SvgParties } from "./assets/parties.svg";
import { ReactComponent as SvgPromo } from "./assets/promo.svg";
import { ReactComponent as SvgNotifications } from "./assets/notifications.svg";
import { ReactComponent as SvgVisibility } from "./assets/visibility.svg";
import { ReactComponent as SvgVisibilityOff } from "./assets/visibility-off.svg";
import { ReactComponent as SvgArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as SvgSearch } from "./assets/search.svg";
import { ReactComponent as SvgSuccess } from "./assets/success.svg";
import { ReactComponent as SvgError } from "./assets/error.svg";
import { ReactComponent as SvgSnackbarSuccess } from "./assets/snackbar-success.svg";
import { ReactComponent as SvgArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as SvgExpandMore } from "./assets/expand-more.svg";
import { ReactComponent as SvgMenu } from "./assets/menu.svg";
import { ReactComponent as SvgClose } from "./assets/close.svg";

export const IconDashboard = (props: SvgIconProps) => (
  <SvgIcon component={SvgDashboard} viewBox="0 0 22 22" {...props} />
);

export const IconPlaces = (props: SvgIconProps) => (
  <SvgIcon component={SvgPlaces} viewBox="0 0 22 22" {...props} />
);

export const IconUsers = (props: SvgIconProps) => (
  <SvgIcon component={SvgUsers} viewBox="0 0 22 22" {...props} />
);

export const IconParties = (props: SvgIconProps) => (
  <SvgIcon component={SvgParties} viewBox="0 0 22 22" {...props} />
);

export const IconPromo = (props: SvgIconProps) => (
  <SvgIcon component={SvgPromo} viewBox="0 0 22 22" {...props} />
);

export const IconNotifications = (props: SvgIconProps) => (
  <SvgIcon component={SvgNotifications} viewBox="0 0 22 22" {...props} />
);

export const IconVisibility = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibility} viewBox="0 0 24 24" {...props} />
);

export const IconVisibilityOff = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibilityOff} viewBox="0 0 24 24" {...props} />
);

export const IconArrowDown = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowDown} viewBox="0 0 20 20" {...props} />
);

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearch} viewBox="0 0 24 24" {...props} />
);

export const IconSnackbarSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSnackbarSuccess} viewBox="0 0 24 24" {...props} />
);

export const IconSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSuccess} viewBox="0 0 16 16" {...props} />
);

export const IconError = (props: SvgIconProps) => (
  <SvgIcon component={SvgError} viewBox="0 0 16 16" {...props} />
);

export const IconArrowLeft = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowLeft} viewBox="0 0 24 24" {...props} />
);

export const IconExpandMore = (props: SvgIconProps) => (
  <SvgIcon component={SvgExpandMore} viewBox="0 0 24 24" {...props} />
);

export const IconMenu = (props: SvgIconProps) => (
  <SvgIcon component={SvgMenu} viewBox="0 0 24 24" {...props} />
);

export const IconClose = (props: SvgIconProps) => (
  <SvgIcon component={SvgClose} viewBox="0 0 24 24" {...props} />
);
